<template>
  <div class="wrapItem">
    <el-table
      :data="tableDatas"
      :height="tableHeight"
      style="width: 100%"
      class="tableWrap"
      ref="rolltable"
      v-if="changeTalbe"
      @mouseenter.native="stopScroll"
      @mouseleave.native="startScroll"
    >
      <el-table-column v-if="shelf" type="selection" width="55">
      </el-table-column>

      <slot name="image"></slot>
      <template v-for="(item, index) in tableTitle">
        <el-table-column
          v-if="!item.hasOwnProperty('label')"
          :prop="item.value"
          :label="item.name"
          :width="item.width"
          align="center"
        >
          <template slot-scope="scope">
            <slot :name="item.value" :scope="scope">
              <img
                v-if="item.value == 'due_days' && scope.row[item.value] < 30"
                src="../assets/image/isdue.png"
                style="width: 40px; vertical-align: bottom; margin-right: 8px"
              />
              <span
                v-if="item.value != 'due_days' || scope.row[item.value] >= 0"
                v-html="
                  scope.row[item.value] &&
                  typeof scope.row[item.value] === 'string'
                    ? scope.row[item.value].replace(/\r\n/g, '<br>')
                    : item.value == 'due_days' && scope.row[item.value] >= 0
                    ? scope.row[item.value] + '天'
                    : scope.row[item.value]
                "
                :style="
                  scope.row[item.value] && scope.row[item.value].length > 30
                    ? 'text-align: left'
                    : 'display: inline-block;'
                "
                :class="hideStatusClass(item.value)"
              ></span>
              <img
                v-if="item.value == 'due_days' && scope.row[item.value] < 0"
                src="../assets/image/dued.png"
                style="width: 40px; vertical-align: bottom; margin-right: 8px"
              />
              <template v-if="item.value == 'status'">
                <img
                  v-if="scope.row[item.value] == 1"
                  src="../assets/image/ing.png"
                  style="width: 30px; vertical-align: bottom; margin-right: 8px"
                />                
                <img
                  v-if="scope.row[item.value] == 2"
                  src="../assets/image/wancheng.png"
                  style="width: 30px; vertical-align: bottom; margin-right: 8px"
                />
                <img
                  v-if="scope.row[item.value] == 3"
                  src="../assets/image/chaoshi.png"
                  style="width: 30px; vertical-align: bottom; margin-right: 8px"
                />
                <span
                v-if="item.value == 'status'"
                v-html="
                  item.value == 'status' && scope.row[item.value] == 1
                    ? '进行中'
                    : item.value == 'status' && scope.row[item.value] == 2
                    ? '已完成'
                    : '已逾期'
                " ></span>
              </template>
            </slot>
          </template>
          <template v-if="item.name === '价格'" slot="header">
            <!-- <span>{{ item.name }}</span> -->
            <img
              class="imgStyle"
              src="../../src/assets/image/price1.png"
              alt=""
            />
          </template>
          <template v-else-if="item.name === '档口'" slot="header">
            <!-- <span>{{ item.name }}</span> -->
            <img
              class="imgStyle"
              src="../../src/assets/image/room1.png"
              alt=""
            />
          </template>
          <template v-else-if="item.name === '产品名'" slot="header">
            <!-- <span>{{ item.name }}</span> -->
            <img
              class="imgStyle"
              src="../../src/assets/image/menu1.png"
              alt=""
            />
          </template>
        </el-table-column>
        <el-table-column v-else :label="item.label">
          <template slot="header" slot-scope="{ column, $index }">
            <span>{{ column.label }}</span>
          </template>
          <el-table-column
            v-for="(it, indexNum) in item.childrens"
            :label="it.name"
            :key="indexNum"
            :prop="it.value"
            :width="it.width"
            align="center"
            show-overflow-tooltip
          >
            <template slot="header" slot-scope="{ column, $index }">
              <div>
                <span v-if="$index % 2 === 0"
                  ><img
                    class="imgStyle"
                    src="../../src/assets/image/menu1.png"
                    alt=""
                /></span>
                <span v-else>
                  <img
                    class="imgStyle"
                    src="../../src/assets/image/price1.png"
                    alt=""
                /></span>
              </div>
            </template>
            <template slot-scope="scope">
              <slot :name="it.value" :scope="scope">{{
                scope.row[it.value]
              }}</slot>
            </template>
          </el-table-column>
        </el-table-column>
      </template>

      <el-table-column  label="操作" width="200" v-if="rect">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small">查看详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
// 引入混入
import { myMixin } from "../common/common.js";

export default {
  data() {
    return {
      tableHeight: undefined,
      heightDate: undefined,
      changeTalbe: true,
      popInformation: {},
    };
  },
  mixins: [myMixin],
  props: {
    tableDatas: {
      type: Array,
      default: [],
    },
    tableTitle: {
      type: Array,
      default: [],
    },
    Heights: {
      type: Number,
      default: 0,
    },
    stockHeight: {
      type: Number,
      default: 0,
    },
    shelf: {
      type: Boolean,
      default: false,
    },
    rect: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    hideStatusClass(value) {
      return value === "status" ? "hide-status" : "";
    },
    handleClick(row) {
      this.popInformation = row;
      this.$emit("customEvent", this.popInformation);
      },
   
    getHeight() {
      if (
        (this.tableHeight =
          document.getElementsByClassName("wrapItem")[0].offsetHeight == 616)
      ) {
        this.tableHeight =
          document.getElementsByClassName("wrapItem")[0].offsetHeight - 70;
      } else if (this.stockHeight) {
        this.tableHeight = this.stockHeight;
      } else {
        this.tableHeight = 545;
      }
    },
    resizeAgine() {
      this.$nextTick(() => {
        this.getHeight();
      });
    },
  },
  watch: {
    Heights: {
      handler(news) {
        this.heightDate = news;
      },
      immediate: true,
    },
    tableTitle: {
      handler(news) {
        this.changeTalbe = false;
        setTimeout(() => {
          this.changeTalbe = true;
        });
        setTimeout(() => {
          this.tableScroll(false);
        }, 100);
      },
      // immediate: true,
    },
  },
  mounted() {
    setTimeout(() => {
      this.getHeight();
      window.addEventListener("resize", this.resizeAgine);
    });
  },
  beforeDestroy() {
    clearInterval(this.rolltimer);
  },
  onUnmounted() {
    window.removeEventListener("resize", this.resizeAgine);
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table td.el-table__cell  {
  text-align: center !important;
}
::v-deep .el-table th.el-table__cell.is-leaf{
  text-align: center !important;
}


::v-deep .el-table--group,
.el-table--border {
  border: none;
}

::v-deep .el-table--border::after {
  width: 0px;
}

.hide-status {
  display: none !important;
}
::v-deep .el-table thead.is-group th.el-table__cell {
  border: 0.5px solid #ffff !important;
}

::v-deep .el-table thead tr > th {
  // border: 2px solid rgb(23,133,153);
  background-color: rgb(1, 52, 92);
}

// 去除多余的gutter
::v-deep .el-table {
  th.gutter,
  colgroup.gutter {
    display: none !important;
    width: 0px !important; //此处的宽度值，对应你自定义滚动条的宽度即可
  }
}
// 关键css代码
::v-deep .el-table__header colgroup col[name="gutter"] {
  display: table-cell !important;
}
::v-deep .el-table__body {
  width: 101% !important;
}

::v-deep .el-table thead {
  border: 2px solid rgb(25, 169, 184) !important;
  color: white;
}

//通用表格样式只有底部线
::v-deep .el-table td.el-table__cell {
  background: rgb(1, 20, 49);
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid rgb(47, 61, 86);
}

::v-deep .el-table thead.is-group th.el-table__cell {
  background: rgb(1, 52, 92) !important;
  text-align: center;
}

::v-deep .container .el-table tbody tr > td {
  background-color: rgb(1, 20, 49);
}

.imgStyle {
  height: 30px;
  width: 30px;
}

.wrapItem {
  width: 100%;
  height: 100%;

  .tableWrap {
    width: 5rem;
    height: 5rem;
    color: #999;
    border-radius: 15px;
    padding: 5px;
    background-color: transparent;

    // border: 1px solid #ffff !important;
  }

  .el-table::before {
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 0px;
    height: 12px;
    /* 横向滚动条的大小 */
  }

  .el-table--border,
  .el-table--group {
    border: none;
  }

  .el-table--border::after,
  .el-table--group::after,
  .el-table::before {
    content: "";
    position: absolute;
    background-color: transparent;
    z-index: 1;
  }
}
</style>
