<template>
  <div class="container">
    <div class="loginCard">
      <div class="img">
        <img src="../assets/image/login-swipcr.c9ec6b49.png" alt="" />
      </div>
      <div class="info">
        <form :model="ruleForm">
          <div class="title">优食安数字食堂云平台</div>
          <div class="username">
            <i class="el-icon-user"></i>
            <input v-model="ruleForm.account" type="text" />
          </div>
          <div class="username">
            <i class="el-icon-lock"></i>
            <input
              v-model="ruleForm.password"
              type="password"
              @keyup.enter="submit"
            />
          </div>
          <!-- 切换地点 -->
          <div style="display: flex; align-items: center">
            <img
              src="../assets/image/xuanze.png"
              style="width: 22px; height: 22px; margin-right: 8px"
            />
            <el-select
              v-model="addressValue"
              placeholder="请选择大屏系统"
              @change="handleAddressChange"
            >
              <el-option
                v-for="item in addressOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="button" style="width: 100%">
            <el-button size="medium" type="primary" round @click="submit"
              >登录</el-button
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { login } from "../api/api"; // 导入我们的api接口
export default {
  data() {
    return {
      ruleForm: {
        account: "",
        password: "",
      },
      addressOptions: [
        {
          value: "0",
          label: "餐厅大屏",
        },
        {
          value: "1",
          label: "内控管理大屏",
        },
      ],
      addressValue: "",
      checked: false,
      redirectto: "",
      navList: [],
    };
  },
  methods: {
    getParams() {
      const params = new URLSearchParams(window.location.search);
      // 获取特定参数的值
      const ac = params.get("ac");
      this.redirectto = params.get("redirectto");
      if (ac !== null) {
        (this.ruleForm.account = ac), (this.ruleForm.password = ac);
        this.submit();
      }
    },
    // 登录
    async submit() {
      if (!this.ruleForm.account) {
        this.$message.error("请输入用户名！");
      } else if (!this.ruleForm.password) {
        this.$message.error("请输入用户密码！");
      } else {
        try {
          const res = await login(this.ruleForm);
          if (res.data.data != null) {
            this.navList = res.data.data.userinfo.nav;
            var { userinfo } = res.data.data;
            var base_flag = res.data?.data.base_flag;
            var localStorageItems = {
              shop_name: userinfo.shop_name,
              shop_users_amount: userinfo.shop_users_amount,
              shop_owner: userinfo.shop_owner,
              shop_phone: userinfo.shop_phone,
              shop_img: userinfo.shop_img,
              shop_address: userinfo.shop_address,
              area: userinfo.area,
              users_amount: userinfo.users_amount,
              store_id: userinfo.store_id,
            };
            Object.entries(localStorageItems).forEach(([key, value]) => {
              localStorage.setItem(key, value);
            });
          }

          if (res.data.code == 0) {
            this.$message.error(res.data.msg);
          } else {
            sessionStorage.setItem("token", userinfo.token);
            sessionStorage.setItem("city_id", userinfo.city_id);
            sessionStorage.setItem("navList", JSON.stringify(this.navList));

            if (sessionStorage.getItem("token")) {
              if (this.checked) {
                if (!base_flag) {
                  this.$router.replace({
                    path: "/InterControl"
                  });
                } else {
                  this.$router.replace({
                    path: "/basicVersion"
                  });
                }
              } else {
                if (this.redirectto) {
                  this.$router.replace({
                    path: "/" + this.redirectto
                  });
                } else {
                  if (!base_flag) {
                    this.$router.replace({
                      path: "/larges"
                    });
                  } else {
                    this.$router.replace({
                      path: "/basicVersion"
                    });
                  }
                }
              }
            }
          }
        } catch (error) {
          console.error("An error occurred:", error);
        }
      }
    },
    // 地点下拉框
    handleAddressChange(value) {
      value == 0 ? (this.checked = 0) : (this.checked = 1);
    },
  },
  mounted() {
    setTimeout(() => {
      this.getParams();
    });
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  background-color: rgb(245, 245, 245);
  display: flex;
  align-items: center;
  justify-content: center;
}
.loginCard {
  border-radius: 5px;
  box-shadow: 0 0 2px 2px #eee;
  background-color: white;
  width: 1020px;
  height: 580px;
  display: flex;
}
.img {
  padding-right: 30px;
}
.info {
  background-color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.info form {
  width: 300px;
  height: 332px;
  /* background-color: aqua; */
}
.info .title {
  padding-bottom: 35px;
  font-size: 28px;
  color: #333;
  text-align: center;
}
.username {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dcdee2;
  margin-bottom: 24px;
}
.username:hover {
  border-bottom-color: rgb(64, 158, 255);
}
.username:hover i {
  color: rgb(64, 158, 255);
}
.username i {
  font-size: 20px;
  margin-right: 10px;
}
.username input {
  width: 100%;
  height: 32px;
  padding-bottom: 1px;
  border: none;
  outline: none;
  background-color: transparent;
}
.rememberPsd {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  padding-bottom: 20px;
}
.rememberPsd .rem {
  padding-left: 10px;
  color: #515a6e;
  font-size: 14px;
}
.forget {
  font-size: 14px;
  color: #515a6e;
}
.forget:hover {
  color: rgb(64, 158, 255);
}
.button {
  text-align: center;
}
.button button {
  margin-top: 40px;
  width: 90%;
}
::v-deep .el-input__inner {
  width: 270px;
  height: 32px;
  line-height: 32px;
}
::v-deep .el-input--suffix .el-input__inner {
  padding-right: 10px;
  padding-left: 8px;
}
::v-deep .el-input__icon {
  line-height: 32px;
  width: 15px;
}
</style>
