<template>
    <!-- 全屏容器 -->
    <v-scale-screen width="1920" height="1080" fullScreen>
      <div class="container">
        <!-- 查看大图容器 -->
        <div class="imgwrap" v-if="isShowImg">
          <img class="next" src="../assets/image/next.png" @click="nextImage()" />
          <img class="up" src="../assets/image/up.png" @click="upImage()" />
          <img :src="bigPic" alt="点击关闭" @click="viewBack" />
        </div>
        <!-- 标题栏部分 -->
        <AiHeader ref="mychild" :prevention="true"></AiHeader>
        <div class="box-container">
          <aside>
            <!-- 学校信息 -->
            <div class="box-left-one">
              <ChartBox title="食堂信息">
                <div
                  class="logo"
                  style="
                    display: flex;
                    justify-content: space-between;
                    margin-top: 20px;
                  "
                >
                  <img
                    :src="shop_img"
                    style="
                      width: 100px;
                      height: 100px;
                      border-radius: 50%;
                      margin-left: 5px;
                      margin-top: 50px;
                    "
                  />
                  <div style="color: white">
                    <div
                      class="phone"
                      style="margin: 10px 0; width: 240px; font-weight: bolder"
                    >
                      <span>{{ shop_name }}</span>
                    </div>
                    <div class="phone" style="margin: 10px 0">
                      <span>负 责 人 ：</span>
                      <span>{{ shop_owner }}</span>
                    </div>
                    <div class="phone" style="margin-bottom: 10px">
                      <span>联系电话：</span>
                      <span>{{ shop_phone }}</span>
                    </div>
                    <div class="phone" style="margin-bottom: 10px">
                      <span>餐厅面积：</span>
                      <span>{{ area }}</span>
                    </div>
                    <div class="phone" style="margin-bottom: 10px">
                      <span>就餐人数：</span>
                      <span>{{ users_amount }}</span>
                    </div>
                    <div
                      class="shop_address"
                      style="line-height: 1.5; width: 240px"
                    >
                      <span>餐厅地址：</span>
                      <span>{{ shop_address }}</span>
                    </div>
                  </div>
                </div>
              </ChartBox>
            </div>
  
            <!-- 防鼠说明-->
            <div class="box-left-two">
              <ChartBox title="防鼠说明">
                <el-carousel height="230px" indicator-position="none">
                  <el-carousel-item
                    v-for="(item, index) in carouselItems"
                    :key="index"
                    style="display: flex; justify-content: center"
                  >
                    <div>
                      <img
                        :src="item.url"
                        alt=""
                        style="width: 320px; height: 210px; margin-top: 10px"
                      />
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </ChartBox>
            </div>
  
            <!-- 培训 -->
            <div class="box-left-three">
              <ChartBox title="培训/考核记录">
                <el-carousel height="230px" indicator-position="none">
                  <el-carousel-item
                  v-for="(item, index) in carouselItems2"
                    :key="index"
                    style="display: flex; justify-content: center"
                  >
                    <div>
                      <img
                        :src="item.url"
                        alt=""
                        style="width: 270px; height: 130px; margin-top: 10px"
                      />
                      <div>
                        <div
                          style="
                            font-size: 12px;
                            border: 1px solid rgb(86, 181, 249);
                            display: inline-block;
                            padding: 5px;
                            border-radius: 5px;
                            color: rgb(86, 181, 249);
                            margin: 5px 20px 10px 0;
                          "
                        >
                          防鼠培训
                        </div>
                        <div
                          style="
                            font-size: 12px;
                            border: 1px solid rgb(86, 181, 249);
                            display: inline-block;
                            padding: 5px;
                            border-radius: 5px;
                            color: rgb(86, 181, 249);
                          "
                        >
                          培训日期：{{item.date}}
                        </div>
                      </div>
                      <div style="color: white; font-size: 12px">
                        <div class="heaName">培训人：{{item.name}}</div>
                        <div class="heaName" style="margin: 5px 0">
                          培训地点：{{item.address}}
                        </div>
                      </div>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </ChartBox>
            </div>
          </aside>
          <section>
            <!-- 证件表格 -->
            <div class="center-buttom">
              <ChartBox title="集中用餐单位食堂防鼠八项措施">
                <otherTranslateSwiper :backgroundImageUrl="backgroundImageUrl"></otherTranslateSwiper>
              </ChartBox>
            </div>
            <!-- 信息展示 -->
            <div class="healthCertificate">
              <ChartBox title="一日两查记录">
                <Swiper :dataList="yrlcList"></Swiper>
              </ChartBox>
            </div>
          </section>
  
          <article>
            <div class="center-top">
              <ChartBox title="视频监控明厨亮灶">
                <template v-slot:selectBox>
                  <el-dropdown size="mini" split-button type="primary">
                    <span class="el-dropdown-link">
                      {{ value2 }}
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        v-for="(item, index) in option2"
                        :key="index"
                        @click.native="changeValue2(item.label)"
                      >
                        <span> {{ item.label }}</span>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
  
                <div class="one">
                  <iframe
                    v-if="checked"
                    src="/iframe.html"
                    id="iframe"
                    frameborder="0"
                    scrolling="no"
                  ></iframe>
                  <el-carousel
                    v-else-if="isCarousel"
                    class="right-ul"
                    height="230px"
                    indicator-position="none"
                  >
                    <el-carousel-item
                      v-for="(item, index) in currentImgList"
                      :key="index"
                    >
                      <div class="right-li">
                        <img
                          :src="item"
                          style="width: 100%; height: 240px; overflow: hidden"
                          @error="handleBImageError"
                          @click="viewLargeVideo(item, index)"
                        />
                      </div>
                    </el-carousel-item>
                  </el-carousel>
  
                  <img
                    style="width: 100%; height: 100%"
                    :src="currentImg || defaultImgB"
                    v-else
                    @error="handleBImageError"
                  />
                </div>
              </ChartBox>
            </div>
            <div class="box-left-one">
              <ChartBox title="防鼠设施设备">
                <el-carousel height="230px" indicator-position="none">
                  <el-carousel-item
                    style="display: flex; justify-content: center"
                  >
                    <div>
                      <img
                        src="../assets//image/fs1.jpg"
                        alt=""
                        style="width: 350px; height: 210px; margin-top: 10px"
                      />
                    </div>
                  </el-carousel-item>
                  <el-carousel-item
                    style="display: flex; justify-content: center"
                  >
                    <div>
                      <img
                        src="../assets//image/fs2.png"
                        alt=""
                        style="width: 350px; height: 210px; margin-top: 10px"
                      />
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </ChartBox>
            </div>
            <!-- 抓拍部分 -->
            <div class="box-right-three">
              <ChartBox title="防鼠AI预警抓拍">
                <div v-if="Snap.length > 0">
                  <img
                    style="height: 232px; width: 100%"
                    src="./../assets/image/NoImg.png"
                    alt=""
                  />
                </div>
                <!-- <el-carousel
                  class="right-ul"
                  height="250px"
                  indicator-position="none"
                  v-else
                >
                  <el-carousel-item v-for="(item, index) in Snap" :key="index">
                    <div class="right-li">
                      <img
                        class="img"
                        :src="item.img"
                        alt=""
                        @click="viewLargeImage(item.img, index)"
                      />
                      <div>
                        <div class="Title">{{ item.title }}</div>
                        <div class="time">抓拍时间：{{ item.time }}</div>
                        <div class="name">
                          抓拍地点：
                          <div>{{ item.address }}</div>
                        </div>
                      </div>
                    </div>
                  </el-carousel-item>
                </el-carousel> -->
              </ChartBox>
            </div>
          </article>
        </div>
      </div>
    </v-scale-screen>
  </template>
  <script>
  import ChartBox from "../components/chartBox.vue";
  import Chart from "../components/chart.vue";
  import AiHeader from "../components/aiHeader.vue";
  import Swiper from "../components/swiper.vue";
  import otherTranslateSwiper from "../components/otherTranslateSwiper.vue";
  import { baseImgUrl } from "../utils/request";
  import { getShopImage, getAiBox,getMouseInfos } from "../api/api";
  // 引入混入
  import { myMixin } from "../common/common.js";
  
  export default {
    data() {
      return {
        carouselItems: [
          { url:require( "../assets/image/zd2.png")},
          { url:require( "../assets/image/zd2.png")},
          { url:require( "../assets/image/zd3.png")},
          { url:require( "../assets/image/zd4.png")}
        ],
        carouselItems2: [
          { url: require("../assets/image/px1.png") ,date:'2024/4/26',name:'张三',address:'杭州' },
          { url: require("../assets/image/px2.png")  ,date:'2024/4/26',name:'张三',address:'杭州' },
        ],
        backgroundImageUrl: [
          { src: require("../assets/image/lb1.png") },
          { src: require("../assets/image/lb2.png") },
          { src: require("../assets/image/lb3.png") },
        ],
        zdList: [
          {
            src: "../assets/image/zd1.png",
          },
          {
            src: "../assets/image/zd2.png",
          },
          {
            src: "../assets/image/zd3.png",
          },
          {
            src: "../assets/image/zd4.png",
          },
        ],
        pxList: [
          {
            src: "../assets/image/px1.png",
          },
          {
            src: "../assets/image/px2.png",
          },
          {
            src: "../assets/image/zd3.png",
          },
        ],
        yrlcList: [
          require("../assets/image/yr1.png"),
          require("../assets/image/yr2.png"),
          require("../assets/image/yr3.png"),
          require("../assets/image/yr4.png"),
          require("../assets/image/yr5.png"),
          require("../assets/image/yr6.png"),
        ],
        isPic: true,
        bigPicIndex: 0,
        dataList: [],
        cleanUpMemory: null,
        suggestImg: "",
        tableTeight: "530",
        tabList: [],
        bigPic: "",
        index: 0,
        currentImgList: [],
        defaultImg: "",
        currentVideo: [],
        imageRequestData: {
          page: 1,
        },
        indexs: -1,
        isShowImg: false,
        arr: [],
        datas: [],
        Snap: [],
      };
    },
    mixins: [myMixin],
    methods: {
      // 查看预警抓拍大图
      viewLargeImage(url, index) {
        this.isPic = true;
        this.bigPicIndex = index;
        this.isShowImg = true;
        this.bigPic = url;
      },
      // 查看大图(视频监控)
      viewLargeVideo(url, index) {
        this.isPic = false;
        this.bigPicIndex = index;
        this.isShowImg = true;
        this.bigPic = url;
      },
      // 上一张
      upImage() {
        let prevIndex = this.bigPicIndex - 1;
        let imgList = this.isPic ? this.Snap : this.currentImgList;
        if (prevIndex < 0) {
          prevIndex = imgList.length - 1;
        }
        this.bigPic = this.isPic ? imgList[prevIndex].img : imgList[prevIndex];
        this.bigPicIndex = prevIndex;
      },
      // 下一张
      nextImage() {
        let nextIndex = this.bigPicIndex + 1;
        let imgList = this.isPic ? this.Snap : this.currentImgList;
  
        if (nextIndex >= imgList.length) {
          nextIndex = 0;
        }
        this.bigPic = this.isPic ? imgList[nextIndex].img : imgList[nextIndex];
        this.bigPicIndex = nextIndex;
      },
      // 返回原图
      viewBack() {
        this.isShowImg = false;
      },
      // 获取抓拍图片
      async getImage() {
        const res = await getShopImage(this.imageRequestData);
        this.Snap = res.data.data.shopImgs.data.map((item, index) => {
          return {
            title: item.output_class_name,
            time: item.created_at,
            img: !item.alarm_image?.includes("http")
              ? baseImgUrl + item.alarm_image
              : item.alarm_image,
            address: item.channel_address,
            chid: item.chid,
          };
        });
      },
      // 获取设备状态和AI设备
      async getAiBox() {
        const res = await getAiBox();
        this.isLocal = res.data.data.aiboxInfo.is_local;
        // 树状结构
        this.datas = [
          {
            label: res.data.data.aiboxInfo.shop_name,
          },
        ];
        this.datas[0].children = res.data.data.channel.map((item, index) => {
          if (index < 4) {
            this.currentVideo.push(item.channel_image);
          }
          return {
            id: item.id,
            label: item.channel_name + "（" + item.channel_address + "）",
            flv_url: item.flv_url,
            channel_image: item.channel_image,
          };
        });
        this.option2 = this.datas[0].children;
        // 自动轮播图片数组
        this.option2.forEach((obj) => {
          // 检查对象中是否有 channel_image 属性
          if (!obj.channel_image?.includes("http")) {
            // 将 channel_image 属性的值添加到 currentImgList 数组中
            this.currentImgList.push(baseImgUrl + obj.channel_image);
          } else {
            this.currentImgList.push(obj.channel_image);
          }
        });
        this.value2 = this.option2[0].label;
        if (this.checked) {
          this.currentImg = this.option2[0].flv_url;
        } else {
          this.currentImg = this.option2[0].channel_image;
        }
        // this.setTwoChart();
      },
      //获取防鼠专题
      async getMouseInfosData(){
        const res = await getMouseInfos();
      }
    },
    components: {
      ChartBox,
      Chart,
      AiHeader,
      otherTranslateSwiper,
      Swiper,
    },
    created() {
      this.getAiBox();
      this.getImage();
      this.getMouseInfosData()
      this.cleanUpMemory = setInterval(() => {
        this.getImage();
      }, 15 * 60 * 1000);
    },
    mounted() {
      document.title = "优食安数字食堂云平台";
    },
  
    beforeDestroy() {
      clearInterval(this.cleanUpMemory);
      this.index = 0;
    },
  };
  </script>
  <style lang="scss" scoped>
  .carouselBox {
    margin: 0 auto;
    width: 100%;
    height: 230px;
    // background-color: #2276f5;
    .carousel {
      width: 100%;
      height: 420px;
      .el-car-item {
        width: 100%;
        height: 420px;
        display: flex;
        .divSrc {
          width: 323px;
          height: 230px;
          background: #fff;
          margin-right: 46px;
  
          .img {
            width: 323px;
            height: 230px;
          }
          .title {
            width: 90%;
            height: 80px;
            line-height: 80px;
            margin: 0 auto;
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            color: #222222;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
      ::v-deep .el-carousel__arrow {
        background: #99a9bf !important;
        display: block !important;
        margin-top: 65px;
      }
    }
    .el-car-item {
      width: 100%;
      display: flex;
  
      .img {
        width: 284px;
        height: 184px;
        margin-right: 20px;
        cursor: pointer;
      }
    }
  }
  .describleImage {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    margin-left: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  // 查看大图样式
  .imgwrap {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 999;
  
    .next {
      position: absolute;
      width: 50px;
      height: 50px;
      right: 30px;
      top: 50%;
    }
    .up {
      position: absolute;
      width: 50px;
      height: 50px;
      left: 30px;
      top: 50%;
    }
  
    img {
      width: 100%;
      height: 100%;
    }
  }
  
  .container // 滚动条样式
    
        ::-webkit-scrollbar-track-piece {
    background: rgb(1, 22, 55);
  }
  
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    /* 横向滚动条的大小 */
  }
  
  ::-webkit-scrollbar-thumb {
    background: #99a9bf;
    border-radius: 20px;
  }
  
  ::v-deep .el-dropdown .el-button-group {
    display: flex;
  }
  ::v-deep .el-button-group > .el-button:not(:last-child) {
    width: 100px;
  }
  ::v-deep .el-button {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  .container {
    height: 100%;
    width: 100%;
    background: url("../assets/image/bg.577ccc4c.jpg");
  
    .box-left-one {
      .logo {
        img {
          width: 180px;
          height: 180px;
        }
  
        .head span,
        .phone span {
          font-size: 15px;
        }
      }
    }
  
    .box-left-whea {
      grid-area: 1/5/2/6;
  
      .wheaItem {
        width: 49%;
        height: calc(100% - 45px);
  
        .wea {
          .wea-sunny {
            i {
              color: rgb(86, 181, 249);
              font-size: 28px;
              margin-right: 5px;
            }
          }
  
          span {
            color: white;
            font-size: 17px;
          }
        }
      }
    }
  }
  
  /* 中间 */
  .box-container {
    min-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: stretch;
    padding: 20px;
    height: calc(100% - 130px);
    > section,
    > aside,
    > article {
      flex: 0 0 20%;
      width: 0;
      > * {
        height: 300px;
        & + * {
          margin-top: 20px;
        }
      }
  
      .center-buttom {
        box-sizing: border-box;
        height: 620px;
        overflow: hidden;
      }
    }
    > section {
      flex: 1;
      margin: 0 20px;
    }
  }
  
  .box-right-one {
    // 树状图
    .filter-tree {
      color: #fff;
      font-size: 12px;
      width: calc(100% - 90px);
      margin: 0 auto;
      padding: 10px;
      max-height: 170px;
      overflow-y: scroll;
      background-color: transparent;
  
      ::v-deep .el-tree-node__expand-icon {
        font-size: 18px;
      }
    }
  }
  
  .box-left-two {
    grid-area: 2/1/3/2;
  
    .temperContainer {
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      color: white;
  
      .tempItem {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
  
        .location {
          // width: 100%;
          height: 50px;
          line-height: 50px;
        }
  
        .temper {
          display: flex;
          align-items: center;
          height: 80px;
  
          img {
            margin-right: 4px;
          }
        }
  
        .water {
          display: flex;
          align-items: center;
          height: 80px;
  
          // margin-left: 8px;
          img {
            margin-right: 3px;
          }
        }
      }
    }
  }
  
  .box-left-three {
    grid-area: 3/1/4/2;
    overflow: hidden;
  }
  
  .center-top {
    .one {
      box-sizing: border-box;
      width: 100%;
  
      margin: 0 auto;
      height: 100%;
      color: white;
      // background-color: black;
      // border: 2px solid rgb(42, 48, 70);
  
      img {
        height: 260px;
        width: 350px;
      }
    }
  }
  
  .center-buttom {
    display: flex;
    justify-content: space-between;
    color: white;
  }
  
  .box-right-three {
    height: 100%;
  
    .right-ul {
      display: flex;
      flex-direction: column;
      max-height: 100%;
  
      .right-li {
        display: flex;
        width: 94%;
        height: 100%;
        padding: 2%;
        background-image: url("../assets/image/img-backgroud.png");
  
        .Title {
          font-size: 16px;
          color: white;
          font-weight: bolder;
          margin-bottom: 8px;
        }
  
        .img {
          width: 72%;
          height: 95%;
          border-radius: 5px;
          margin-right: 10px;
        }
  
        .time,
        .name {
          font-size: 12px;
          color: white;
          margin-top: 15px;
        }
      }
    }
  }
  
  .currentBg {
    background: rgb(0, 228, 255) !important;
  }
  
  .noCurrentBg {
    color: white !important;
  }
  </style>
  <style lang="scss" scoped>
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
  // 表单格式
  ::v-deep .el-table {
    .el-table__body {
      height: 100%;
    }
  }
  
  // :not(.el-table thead.is-group th.el-table__cell)
  ::v-deep .box-container .el-table .el-table__cell {
    // border-right: 1px solid white;
    padding: 20px 7px;
    color: white !important;
  }
  ::v-deep .el-table th.el-table__cell.is-leaf {
    padding: 20px 0 !important;
    font-size: 16px;
    border: none !important;
  }
  ::v-deep .box-container .el-tabs .el-tabs__item:not(:first-child) {
    color: #a27b67;
  }
  
  ::v-deep .box-container .el-tabs .el-tabs__item:hover {
    color: #a27b67 !important;
  }
  
  .el-tabs__active-bar {
    background-color: #a27b67 !important;
  }
  
  .box-container .el-tabs {
    width: 100%;
    color: #fffff0;
  }
  
  .center-buttom .el-tab-pane .el-image {
    width: 40px;
    height: 40px;
  }
  
  // Card样式
  .bottom {
    margin-top: 13px;
    line-height: 12px;
  }
  
  .button {
    padding: 0;
    float: right;
  }
  
  .image {
    width: 50%;
    // display: block;
  }
  
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  
  .clearfix:after {
    clear: both;
  }
  
  .center-buttom .el-button {
    padding: 3px 4px;
  }
  </style>
  