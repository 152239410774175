<template>
  <!-- 全屏容器 -->
  <v-scale-screen width="1920" height="1080" fullScreen>
    <div class="container">
      <!-- 查看大图容器 -->
      <div class="imgwrap" v-if="isShowImg">
        <img
          @click="viewBack"
          class="close"
          src="../assets/image/close.png"
          alt=""
        />
        <img :src="bigPic" alt="" />
      </div>
      <!-- 标题栏部分 -->
      <AiHeader></AiHeader>
      <div class="box-container">
        <aside>
          <!-- AI设备 -->
          <div class="box-left-one">
            <ChartBox title="智能AI监控">
              <el-tree
                :default-expand-all="true"
                icon-class="el-icon-s-unfold"
                @node-click="play"
                class="filter-tree"
                :props="defaultProps"
                :data="datas"
                ref="tree"
              >
              </el-tree>
            </ChartBox>
          </div>

          <div class="box-left-tow">
            <ChartBox title="温度湿度监控">
              <div style="height: 100%">
                <div style="display: flex; margin-top: 5px; margin-left: 10px">
                  <template v-if="switchValue">
                    <div
                      class="icon"
                      style="
                        display: flex;
                        width: 35px;
                        height: 35px;
                        justify-content: center;
                        align-items: center;
                        border: 1px solid rgb(71, 238, 249);
                      "
                    >
                      <img
                        src="../assets/image/temp.png"
                        style="width: 18px; height: 18px"
                      />
                    </div>
                    <div v-for="(item, index) in tempArr" :key="index">
                      <div
                        class="theTop"
                        style="
                          margin-left: 5px;
                          padding: 3px 10px;
                          height: 34px;
                          color: rgb(71, 238, 249);
                          font-size: 12px;
                          background-color: rgb(17, 40, 54);
                          border-radius: 5px;
                          display: flex;
                          flex-direction: column;
                          justify-content: space-between;
                        "
                      >
                        <div>{{ item.name }}</div>
                        <div>{{ item.num }}℃</div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div
                      class="icon"
                      style="
                        display: flex;
                        width: 35px;
                        height: 35px;
                        justify-content: center;
                        align-items: center;
                        border: 1px solid rgb(71, 238, 249);
                      "
                    >
                      <img
                        src="../assets/image/humidity.png"
                        style="width: 18px; height: 18px"
                      />
                    </div>
                    <div v-for="(item, index) in humidityArr" :key="index">
                      <div
                        class="theTop"
                        style="
                          margin-left: 5px;
                          padding: 3px 10px;
                          height: 34px;
                          color: rgb(71, 238, 249);
                          font-size: 12px;
                          background-color: rgb(17, 40, 54);
                          border-radius: 5px;
                          display: flex;
                          flex-direction: column;
                          justify-content: space-between;
                        "
                      >
                        <div>{{ item.name }}</div>
                        <div>{{ item.num }} %rh</div>
                      </div>
                    </div>
                  </template>
                  <el-switch
                    @change="swichChange()"
                    style="margin-left: 30px"
                    v-model="switchValue"
                    active-text="温度"
                    inactive-text="湿度"
                    inactive-color="#13ce66"
                    active-color="#ff4949"
                    class="custom-switch"
                  >
                  </el-switch>
                </div>
                <Chart
                  width="100%"
                  height="87%"
                  :option="ChartTemperature"
                ></Chart>
              </div>
            </ChartBox>
          </div>

          <!-- 各通道抓拍图片数量统 -->
          <!-- <div class="box-left-two">
			  <ChartBox title="各监控预警违规抓拍数量">
				<Chart width="95%" height="100%" :option="Chartone"></Chart>
			  </ChartBox>
			</div> -->
          <!-- 其他感测 -->
          <div class="box-left-three">
            <ChartBox title="物联网设备监控">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  height: 100%;
                  font-size: 12px;
                "
              >
                <div
                  style="
                    width: 50%;
                    height: 100%;
                    color: white;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  "
                >
                  <!-- 烟雾 -->
                  <Chart
                    width="75%"
                    height="72%"
                    :option="ChartSensing"
                  ></Chart>
                  <div
                    class="state"
                    style="
                      width: 60%;
                      background-color: rgba(2, 52, 93, 0.452);
                      padding: 5px;
                      margin-bottom: 5px;
                      border-radius: 5px;
                    "
                  >
                    当前状态：<span style="color: rgb(37, 199, 122)">{{
                      smogStatus
                    }}</span>
                  </div>
                  <div
                    class="stateNum"
                    style="
                      width: 60%;
                      background-color: rgb(2, 52, 93, 0.452);
                      padding: 5px;
                      border-radius: 5px;
                    "
                  >
                    预警次数：<span style="color: rgb(37, 199, 122)">{{
                      smogAlertTimes
                    }}</span>
                  </div>
                </div>
                <!-- 水浸 -->
                <div
                  style="
                    width: 50%;
                    height: 100%;
                    color: white;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  "
                >
                  <Chart
                    width="100%"
                    height="72%"
                    :option="ChartSensing2"
                  ></Chart>
                  <div
                    class="state"
                    style="
                      width: 60%;
                      background-color: rgba(2, 52, 93, 0.452);
                      padding: 5px;
                      margin-bottom: 5px;
                      border-radius: 5px;
                    "
                  >
                    当前状态：<span style="color: rgb(37, 199, 122)">{{
                      waterStatus
                    }}</span>
                  </div>
                  <div
                    class="stateNum"
                    style="
                      width: 60%;
                      background-color: rgb(2, 52, 93, 0.452);
                      padding: 5px;
                      border-radius: 5px;
                    "
                  >
                    预警次数：<span style="color: rgb(37, 199, 122)">{{
                      waterAlertTimes
                    }}</span>
                  </div>
                </div>
              </div>
            </ChartBox>
          </div>
          <!-- 监控设备状态 -->
          <!-- <div class="box-left-tow">
			  <ChartBox title="监控设备状态">
				<div
				  style="
					display: flex;
					justify-content: space-between;
					height: 100%;
				  "
				>
				  <Chart width="100%" height="99%" :option="twoChart"></Chart>
				  <Chart width="100%" height="99%" :option="twoChart2"></Chart>
				</div>
			  </ChartBox>
			</div> -->
        </aside>

        <section>
          <!-- 实时调阅 -->
          <div class="center-top">
            <ChartBox title="视频监控">
              <template v-slot:selectBox>
                <div class="title-box">
                  <el-checkbox
                    @change="changeCheckbox"
                    v-model="checked"
                    style="color: #fff"
                    >本地监控视频播放
                  </el-checkbox>
                  <div class="center-top-title">
                    <div class="text">
                      <div>分屏：</div>
                    </div>
                    <div class="Split">
                      <div
                        class="oneScreen"
                        @click="changeOne"
                        :class="{ currentBg: isOne }"
                      ></div>
                      <div
                        class="fourScreen"
                        :class="{ noCurrentBg: isOne }"
                        @click="changeFour"
                      >
                        <i class="el-icon-menu"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <div class="one" v-show="isOne">
                <jessibucaDemo
                  v-if="checked"
                  style="width: 100%; height: 100%"
                  :url="currentImg"
                />
                <img
                  :src="currentImg || defaultImgB"
                  v-else
                  @error="handleBImageError"
                />
              </div>
              <div class="four" v-show="!isOne">
                <div class="four-container" v-for="(item, i) in 4" :key="i">
                  <div
                    @click="changIndex(i, currentVideo[i])"
                    class="four-item"
                    :class="{ active: indexs == i }"
                  >
                    <jessibucaDemo
                      v-if="checked"
                      style="width: 100%; height: 100%"
                      :url="currentVideo[i]"
                    />
                    <img
                      style="width: 100%; height: 100%"
                      :src="currentVideo[i] || defaultImg"
                      v-else
                      @error="handleImageError"
                    />
                  </div>
                </div>
              </div>
            </ChartBox>
          </div>
          <div class="center-buttom">
            <TabPane :tableTeight="tableTeight" :tabList="tabList"></TabPane>
          </div>
        </section>

        <!-- 抓拍部分 -->
        <div class="box-right">
          <ChartBox title="预警抓拍">
            <div class="right-ul">
              <div v-for="(item, index) in Snap" :key="index">
                <div class="right-li">
                  <div class="Title">
                    <div>{{ item.title }}</div>
                    <i
                      @click="viewLargeImage(item.img)"
                      class="el-icon-rank"
                    ></i>
                  </div>
                  <img class="img" :src="item.img" alt="" />
                  <div class="time">抓拍时间：{{ item.time }}</div>
                  <div class="name">
                    抓拍地点：{{ item.chid }}（{{ item.address }}）
                  </div>
                </div>
              </div>
            </div>
          </ChartBox>
        </div>
      </div>
    </div>
  </v-scale-screen>
</template>
<script>
import ChartBox from "../components/chartBox.vue";
import Chart from "../components/chart.vue";
import AiHeader from "../components/aiHeader.vue";
import TabPane from "../components/tabPane.vue";
import * as echarts from "echarts";
import "echarts-liquidfill";
// 引入混入
import { myMixin } from "../common/common.js";
import {
  getAiBox,
  getShopImage,
  getShopChannelImgs,
  getTemperature,
} from "../api/api";

export default {
  data() {
    return {
      tableTeight: "230",
      tabList: [
        {
          label: "健康证",
          name: "1",
        },
        {
          label: "每周菜谱",
          name: "2",
        },
        {
          label: "品尝记录",
          name: "3",
        },
        {
          label: "每日留样",
          name: "4",
        },
        {
          label: "废弃物处理",
          name: "5",
        },
        {
          label: "晨检记录",
          name: "6",
        },
        {
          label: "餐具消毒",
          name: "7",
        },
        {
          label: "操作间消毒",
          name: "8",
        },
        {
          label: "区域八净",
          name: "9",
        },
        {
          label: "排水沟清洁",
          name: "10",
        },
        {
          label: "操作间自检",
          name: "11",
        },
        {
          label: "冷库巡检",
          name: "12",
        },
        {
          label: "虫害防治",
          name: "13",
        },
        {
          label: "闭店安检",
          name: "14",
        },
        {
          label: "供应商资质管理",
          name: "15",
        },
      ],
      TemperatureDate: [], //温度日期
      tempDate: [],
      TemperatureVal: [], //温度数值
      WetDate: [], //湿度数值
      smogVal: [], //烟雾数值
      smogStatus: "", //烟雾当前状态
      smogAlertTimes: 0, //烟雾警告次数
      waterStatus: "", //水浸当前状态
      waterAlertTimes: 0, //水浸警告次数
      waterDate: [],
      switchValue: true,
      tempArr: [
        {
          num: 28,
          name: "最高温度",
        },
        {
          name: "最低温度",
          num: 0,
        },
      ],
      humidityArr: [
        {
          name: "最高湿度",
          num: 100,
        },
        {
          name: "最低湿度",
          num: 0,
        },
      ],
      checked: false, //视频流是否开启
      bigPic: "",
      isLocal: "", //是否是本地
      index: 0,
      videoList: [],
      currentImg: "",
      defaultImgB: "",
      defaultImg: "",
      currentVideo: [],
      imageRequestData: {
        page: 1,
      },
      timer: null, //定时器
      isOne: false,
      indexs: -1,
      isShowImg: false,
      arr: [],
      datas: [],
      Snap: [],
      rowStyle: {
        color: "#666",
        fontSize: "12px",
        height: "32px",
      },
      interval: "",
      Chartone: {}, //图表配置项（各通道抓拍图片数量统）
      ChartSensing: {}, //图表配置项（火灾预警【烟雾】）
      ChartSensing2: {}, //图表配置项（火灾预警【浸水】）
      ChartTemperature: {}, //图表配置项（温度）
      // 图表配置项（设备状态）
      twoChart: {},
      twoChart2: {},
      //  图表数据（设备状态）
      twoChartDate: {
        data: [
          {
            value: 148,
            name: "启用",
          },
          {
            value: 735,
            name: "未启用",
          },
        ],
      },
      twoChartDate2: {
        data: [
          {
            value: 1048,
            name: "在线",
          },
          {
            value: 735,
            name: "离线",
          },
        ],
      },
      SensingChartDate: {}, //其他感测数据
      // 设备状态
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  mixins: [myMixin],
  methods: {
    swichChange() {
      this.tempDate = this.switchValue ? this.TemperatureVal : this.WetDate;
      this.setTemperature();
    },
    handleImageError() {
      this.defaultImg = "http://aibigscreen.ufoodlife.cn/img/nosignal1.png"; // 图片加载错误时，将src属性绑定到默认的无信号图片路径
    },
    // checkbox的change事件
    changeCheckbox(e) {
      this.index = 0;
      this.indexs = -1;
      this.checked = e;
      if (this.checked) {
        this.currentVideo = [];
      } else {
        this.currentVideo = [];
        this.getAiBox();
      }
    },
    // 播放异常 重新拉流
    pullFlow() {},
    // 播放事件
    play(a) {
      this.index++;
      if (a.children) {
        this.index = 0;
        this.indexs = -1;
        Array.prototype.push.apply(this.videoList, a.children);
      } else {
        this.indexs++;
        this.currentImg = !this.checked ? a.channel_image : a.flv_url;
        if (this.index < 5) {
          this.currentVideo.splice(this.index - 1, 1, this.currentImg);
        } else {
          this.indexs = 3;
          this.currentVideo.splice(
            this.currentVideo.length - 1,
            1,
            this.currentImg
          );
        }
      }
    },
    // 树状节点
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    setChart() {
      const listData = this.arr.sort((a, b) => {
        return a.value - b.value;
      });
      const max = Math.max(...listData.map((r) => r.value));
      const spanColors = ["#13B5B1", "#FFF100", "#0195C2", "#00FFC0"];
      const spanStyles = {};

      for (let i = 0; i < spanColors.length; i++) {
        const label = `0${i}`;
        spanStyles[`span${label}`] = {
          width: 10,
          height: 10,
          borderRadius: 5,
          shadowColor: spanColors[i],
          shadowBlur: 10,
          backgroundColor: spanColors[i],
        };
        if (i > 0) {
          spanStyles[`a0${label}`] = {
            color: spanColors[i],
          };
        }
      }
      return (this.Chartone = {
        tooltip: {
          show: false,
        },
        grid: {
          top: "5%",
          left: "12%",
          right: "10%",
          bottom: "2%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          axisLabel: {
            color: "#fff",
          },
          splitLine: {
            show: false,
          },
          max,
        },
        yAxis: {
          type: "category",
          offset: 20,
          axisLabel: {
            margin: -10,
            padding: [0, 5, 0, 0],
            color: "#fff",
            formatter: (name, index) => {
              const obj = {
                0: "01",
                1: "02",
                2: "03",
              };
              const label = obj[10 - index] || "00";
              return `{a${label}|${name}}{b|} {span${label}|}`;
            },
            rich: {
              b: {
                width: 5,
              },
              ...spanStyles,
            },
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: "rgba(21, 200, 221,0.2)",
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          data: listData.map((r) => r.name),
        },
        series: [
          {
            name: "地市资源排行",
            type: "bar",
            barWidth: 8,
            label: {
              show: false,
            },
            data: listData.map((r, i) => {
              const itemStyle = {
                borderWidth: 2,
                color: "#13B5B1",
                borderRadius: 8, //圆角
                borderColor: "#041921",
              };
              if (i >= 8 && i <= 10) {
                const colors = ["#00FFC0", "#0195C2", "#FFF100"];
                itemStyle.color = colors[i - 8];
              }

              return {
                ...r,
                itemStyle,
              };
            }),
            z: 1,
          },
          {
            type: "bar",
            barGap: "-105%", // 柱形图偏移
            data: listData.map((items, i) => {
              const value = Math.max(...listData.map((item) => item.value));
              const itemStyle = {
                color: "none",
                borderRadius: 8, //圆角
                borderColor: "#041921",
              };
              if (i >= 8 && i <= 10) {
                const colors = ["#00FFC0", "#0195C2", "#FFF100"];
                itemStyle.color = colors[i - 8];
              }
              return {
                value,
              };
            }),
            barWidth: 10,
            itemStyle: {
              color: "none",
              borderRadius: 8, //圆角
              borderColor: "#13B5B1",
            },
            label: {
              show: true,
              position: "right",
              color: "#13B5B1",
              fontSize: 12,
              formatter: (params) => {
                const name = params.name;
                const value = listData.find((item) => item.name === name).value;
                return value;
              },
            },
            z: 0,
          },
        ],
      });
    },
    // 设备使用情况
    setTwoChart() {
      return (this.twoChart = {
        tooltip: {
          trigger: "item",
          // show:true,
          // position: [10, 10]
        },
        legend: {
          top: "84%",
          left: "center",
          textStyle: {
            fontSize: 10,
            color: "#ffffff", //字体颜色
          },
        },
        series: [
          {
            name: "设备使用情况",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              fontSize: 9,
              show: true,
              position: "center",
              formatter: "{b}: {c}",
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 20,
              smooth: true,
            },
            emphasis: {
              label: {
                color: "#fff",
                show: true,
                fontSize: 14,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: this.twoChartDate.data,
          },
        ],
      });
    },
    // 设备在线数量
    setTwoChart2() {
      return (this.twoChart2 = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "84%",
          left: "center",
          textStyle: {
            fontSize: 10,
            color: "#ffffff", //字体颜色
          },
        },
        series: [
          {
            name: "设备在线数量",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              fontSize: 9,
              show: true,
              position: "center",
              formatter: "{b}: {c}",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 14,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: this.twoChartDate2.data,
          },
        ],
      });
    },
    // 烟雾
    setSensingChart() {
      var dataArr = [this.smogVal];
      // var backgroundColor = "#0E1327";
      var center_1 = ["50%", "50%"];
      var radius_1 = "80%";
      var color_offset_0 = "#19fdab";
      var color_offset_1 = "#d0ff19";
      var color_offset_2 = "#ff4026";
      var line_width = "5";
      var anchor_color = "#0e1327";
      var anchor_size = 10;
      var pointer_length = "90%";
      var pointer_width = 6;
      var pointer_offsetCenter = [0, "5%"];
      var pointer_color_offset_0 = "#FEAD54";
      var pointer_color_offset_1 = "#0e1327";
      var detail_color = "red";
      this.ChartSensing = {
        title: {
          text: [`{a| 烟雾感测 }`, `{b|${this.smogVal}ppm}`].join(""),
          left: "10%",
          bottom: -4,
          itemGap: 2, // 副标题和主标题之间的距离，默认是10
          textStyle: {
            rich: {
              a: {
                color: "#fff",
                fontSize: 13,
                fontWeight: "500",
              },
              b: {
                fontFamily: "Microsoft Yahei",
                fontWeight: "normal",
                color: "#bcb8fb",
                fontSize: 13,
              },
            },
          },
        },

        series: [
          {
            data: dataArr,
            min: 0,
            max: 2000,
            name: "最外层",
            type: "gauge",
            roundCap: true,
            radius: radius_1,
            center: center_1,
            splitNumber: 10, //刻度数量
            startAngle: 225,
            endAngle: -45,
            z: 4,
            zlevel: 0,
            axisLine: {
              show: true,
              roundCap: true,
              lineStyle: {
                // 轴线样式
                width: line_width, // 宽度
                color: [
                  [
                    1,
                    new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                      {
                        offset: 0,
                        color: color_offset_0,
                      },
                      {
                        offset: 0.5,
                        color: color_offset_1,
                      },
                      {
                        offset: 1,
                        color: color_offset_2,
                      },
                    ]),
                  ],
                ],
              },
            },
            //分隔线样式
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              show: true,
            },
            anchor: {
              show: true,
              size: anchor_size,
              showAbove: true,
              itemStyle: {
                color: anchor_color,
              },
            },
            pointer: {
              icon: "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
              show: true,
              length: pointer_length,
              width: pointer_width,
              offsetCenter: pointer_offsetCenter,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: pointer_color_offset_0,
                  },
                  {
                    offset: 1,
                    color: pointer_color_offset_1,
                  },
                ]),
              },
            },
            detail: {
              formatter: "{value}ppm",
              color: detail_color,
              fontFamily: "Microsoft Yahei",
              fontWeight: "normal",
              color: "#bcb8fb",
              fontSize: 13,
              show: false,
            },
          },
          {
            name: "第二层刻度",
            type: "gauge",
            center: ["50%", "50%"],
            radius: "75%",
            // distance : 100,
            startAngle: 225,
            endAngle: -45,
            axisLine: {
              show: false,
            }, //仪表盘轴线
            axisLabel: {
              show: false,
              color: "#FFFFFF",
              distance: 20,
            }, //刻度标签。
            axisTick: {
              show: true,
              splitNumber: 5,
              lineStyle: {
                color: "#f40", //用颜色渐变函数不起作用
                width: 1,
              },
              distance: 20,
              length: 3,
            },
            splitLine: {
              show: false,
            },
            detail: {
              show: false,
            },
            pointer: {
              show: false,
            },
          },
          {
            name: "内部细刻度",
            type: "gauge",
            center: ["50%", "50%"],
            radius: "55%",
            startAngle: 225,
            endAngle: -45,
            axisLine: {
              show: true,
              lineStyle: {
                width: 1,
                color: [
                  [
                    1,
                    new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                      {
                        offset: 0,
                        color: "#224570",
                      },
                      {
                        offset: 1,
                        color: "#224570",
                      },
                    ]),
                  ],
                ],
              },
            }, //仪表盘轴线
            axisTick: {
              show: false,
            },
            //刻度线文字
            axisLabel: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            detail: {
              show: false,
            },
            pointer: {
              show: false,
            },
          },
        ],
      };
    },
    // 浸水
    setSensingChart2() {
      this.ChartSensing2 = {
        title: {
          text: (this.waterDate * 100).toFixed(0) + "%",
          textStyle: {
            fontSize: 13,
            fontFamily: "Microsoft Yahei",
            fontWeight: "normal",
            color: "#bcb8fb",
            rich: {
              a: {
                fontSize: 28,
              },
            },
          },
          x: "65%",
          y: "86%",
        },
        series: [
          {
            name: "预估量",
            type: "liquidFill",
            radius: "60%",
            center: ["50%", "50%"],
            backgroundStyle: {
              color: "transparent",
            },
            data: [this.waterDate],
            amplitude: 20, //水波振幅
            label: {
              //标签设置
              position: ["50%", "130%"],
              formatter: "水浸感测", //显示文本,
              textStyle: {
                fontSize: "13px", //文本字号,
                color: "#fff",
              },
            },
            outline: {
              borderDistance: 3,
              itemStyle: {
                borderWidth: 2,
                borderColor: {
                  type: "linear",
                  x: 1,
                  y: 0,
                  x2: 0,
                  y2: 0,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#007DFF",
                    },
                    {
                      offset: 0.6,
                      color: "rgba(45, 67, 114, 1)",
                    },
                    {
                      offset: 1,
                      color: "rgba(45, 67, 114, 1)",
                    },
                  ],
                  globalCoord: false,
                },
              },
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {
                  offset: 1,
                  color: "rgba(31, 222, 225, 1)",
                },
                {
                  offset: 0.85,
                  color: "#007DFF80",
                },
                {
                  offset: 0.35,
                  color: "#004a99",
                },
                {
                  offset: 0,
                  color: "rgba(31, 222, 225, 1)",
                },
              ]),
            },
          },
        ],
      };
    },
    setTemperature() {
      this.ChartTemperature = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            lineStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(255,255,255,0)", // 0% 处的颜色
                  },
                  {
                    offset: 0.5,
                    color: "rgba(255,255,255,1)", // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(255,255,255,0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        },
        grid: {
          top: "18%",
          left: "1%",
          right: "1%",
          bottom: "18%",
          // containLabel: true
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: true,
            axisLine: {
              //坐标轴轴线相关设置。数学上的x轴
              show: true,
              lineStyle: {
                color: "rgba(255,255,255,0.4)",
              },
            },
            axisLabel: {
              //坐标轴刻度标签的相关设置
              textStyle: {
                color: "#d1e6eb",
                margin: 15,
              },
              // rotate: -35,
            },
            axisTick: {
              show: false,
            },
            data: this.TemperatureDate,
          },
        ],
        yAxis: [
          {
            type: "value",
            min: 0,
            // max: 140,
            splitNumber: 4,
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(255,255,255,0.1)",
              },
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: false,
              margin: 20,
              textStyle: {
                color: "#d1e6eb",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "注册总量",
            type: "line",
            // smooth: true, //是否平滑曲线显示
            // 			symbol:'circle',  // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            // symbol: 'image://./static/images/guang-circle.png',
            symbolSize: 4,
            lineStyle: {
              normal: {
                color: "#6c50f3", // 线条颜色
              },
              borderColor: "#f0f",
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#fff",
              },
            },
            itemStyle: {
              normal: {
                color: "rgba(255,255,255,1)",
              },
            },
            tooltip: {
              show: false,
            },
            areaStyle: {
              //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(0,150,239,0.3)",
                    },
                    {
                      offset: 1,
                      color: "rgba(0,253,252,0)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(53,142,215, 0.9)", //阴影颜色
                shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              },
            },
            data: this.tempDate,
          },
        ],
      };
    },
    // 改变分屏
    changeOne() {
      this.isOne = true;
      this.currentImg = this.currentVideo[0];
      this.currentVideo = [];
      // console.log(this.currentVideo,'changeOne');
    },
    changeFour() {
      this.isOne = false;
      this.currentVideo = [];
      this.currentVideo[0] = this.currentImg;
      // console.log(this.currentVideo,'changeFour');
      if (this.index != 0) {
        this.index = 1;
      }
      this.indexs = 0;
    },
    changIndex(e, i) {
      this.indexs = e;
      this.index = e;
    },
    // 查看大图
    viewLargeImage(url) {
      this.isShowImg = true;
      this.bigPic = url;
    },
    // 返回原图
    viewBack() {
      this.isShowImg = false;
    },
    // 获取设备状态和AI设备
    async getAiBox() {
      const res = await getAiBox();
      this.isLocal = res.data.data.aiboxInfo.is_local;
      // 启用数量
      this.twoChartDate.data[0].value = res.data.data.user;
      this.twoChartDate.data[1].value = res.data.data.notUser;
      // 在线数量
      this.twoChartDate2.data[0].value = res.data.data.online;
      this.twoChartDate2.data[1].value = res.data.data.offline;
      // 树状结构
      this.datas = [
        {
          label: res.data.data.aiboxInfo.shop_name,
        },
      ];
      this.datas[0].children = res.data.data.channel.map((item, index) => {
        if (index < 4) {
          this.currentVideo.push(item.channel_image);
        }
        return {
          id: item.id,
          label: item.channel_name + "（" + item.channel_address + "）",
          flv_url: item.flv_url,
          channel_image: item.channel_image,
        };
      });
      this.setTwoChart();
      this.setTwoChart2();
    },
    // 获取抓拍图片
    async getImage() {
      const res = await getShopImage(this.imageRequestData);
      this.Snap = res.data.data.shopImgs.data.map((item, index) => {
        return {
          title: item.output_class_name,
          time: item.created_at,
          img: item.alarm_image,
          address: item.channel_address,
          chid: item.chid,
        };
      });
    },
    // 获取通道抓拍数量
    async getShopChannelImg() {
      const res = await getShopChannelImgs();
      const point = Object.values(res.data.data.channel_address);
      this.arr = Object.entries(res.data.data.imgsCount)
        .map(([key, value], i) => ({
          name: key + "（" + point[i] + "）",
          value: value,
        }))
        .slice(1);
      this.setChart();
    },
    // 获取温温度数据getTemperature,getSmoke,getImmersionAmount
    async getTemperatures() {
      // 获取温温度数据
      const res = await getTemperature();
      this.tempArr[0].num = res.data.data.max_temp;
      this.tempArr[1].num = res.data.data.min_temp;
      this.humidityArr[0].num = res.data.data.max_wet;
      this.humidityArr[1].num = res.data.data.min_wet;
      for (var i = 0; i < res.data.data.temp.date.length; i++) {
        var item = res.data.data.temp.date[i];
        var truncatedItem = item.substring(5, 10);
        this.TemperatureDate.push(truncatedItem);
      }
      this.TemperatureVal = res.data.data.temp.val;
      this.tempDate = this.TemperatureVal; //初始温度数值
      this.WetDate = res.data.data.wet.val;
      // 烟雾
      this.smogVal = res.data.data.smog.val;
      this.smogStatus = res.data.data.smog.status;
      this.smogAlertTimes = res.data.data.smog.alertTimes;
      // 浸水
      this.waterStatus = res.data.data.water.status;
      this.waterAlertTimes = res.data.data.water.alertTimes;
      this.waterDate = res.data.data.water.val / 100;
      this.setTemperature();
      this.setSensingChart();
      this.setSensingChart2();
    },
  },
  components: {
    ChartBox,
    Chart,
    AiHeader,
    TabPane,
  },
  created() {
    this.getAiBox();
    this.getImage();
    // this.getShopChannelImg();
    // setTimeout(() => {
    this.getTemperatures();
    // }, 500);
  },
  mounted() {
    this.timer = setInterval(() => {
      this.getAiBox();
      // this.getShopChannelImg();
    }, 30000);
  },

  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.timer);
    this.index = 0;
  },
};
</script>
<style lang="scss" scoped>
// 查看大图样式
.imgwrap {
  height: 100vh;
  width: 100%;
  position: relative;

  .close {
    position: absolute;
    width: 50px;
    height: 50px;
    right: 10px;
    top: 10px;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.container // 滚动条样式

	::-webkit-scrollbar-track-piece {
  background: rgb(1, 22, 55);
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: #99a9bf;
  border-radius: 20px;
}

.container {
  height: 100%;
  width: 100%;
  background: url("../assets/image/bg.577ccc4c.jpg");
}

/* 中间 */
.box-container {
  min-width: 1200px;
  padding: 20px;
  height: calc(100% - 130px);
  display: flex;
  > section,
  > aside,
  > .box-right {
    flex: 0 0 20%;
    width: 0;
    > * {
      height: 300px;
      box-sizing: border-box;
      & + * {
        margin-top: 20px;
      }
    }

    .center-top {
      height: 620px;
    }
  }
  > .box-right {
    height: 940px;
    > * {
      max-height: none;
      height: 100%;
    }
  }
  > section {
    flex: 1;
    margin: 0 20px;
    .center-top > .box {
      max-height: none;
    }
  }
}

.box-left-one {
  grid-area: 1/1/2/2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  // 树状图
  .filter-tree {
    color: #fff;
    font-size: 12px;
    width: calc(100% - 90px);
    margin: 0 auto;
    padding: 10px;
    max-height: 190px;
    overflow-y: scroll;
    background-color: transparent;

    ::v-deep .el-tree-node__expand-icon {
      font-size: 18px;
    }
  }
}

.box-left-two {
  grid-area: 2/1/3/2;
}

.box-left-three {
  grid-area: 3/1/4/2;
  overflow: hidden;
}

.center-top {
  grid-area: 1/2/3/5;

  .title-box {
    width: 910px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .center-top-title {
      display: flex;

      .text {
        color: white;
        font-size: 1px;
      }

      .Split {
        width: 52px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .oneScreen {
          width: 16px;
          height: 16px;
          background: #fff;
        }

        .fourScreen {
          font-size: 22px;
          color: rgb(0, 228, 255);
        }

        img:active {
          background-color: rgb(0, 228, 255);
        }
      }
    }
  }

  .one {
    box-sizing: border-box;
    width: 99%;
    margin: 0 auto;
    height: 95%;
    color: white;
    background-color: black;
    border: 2px solid rgb(42, 48, 70);

    img {
      width: 100%;
      height: 100%;
    }
  }

  .four {
    width: 100%;
    height: 98%;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-evenly;
    align-content: space-evenly;

    .four-container {
      width: 49%;
      height: 49%;

      .four-item {
        box-sizing: border-box;
        width: 99%;
        margin: 0 auto;
        height: 98%;
        color: white;
        border: 1px solid #0061b7;
        background-color: black;
      }

      .active {
        border: 2px solid rgb(42, 48, 70);
      }
    }
  }
}

.center-buttom {
  grid-area: 3/2/4/5;
  display: flex;
  justify-content: space-between;
  color: white;
  border: 2px solid rgb(34, 77, 128);
  border-radius: 10px;
  padding: 0 10px;
}

.box-right {
  grid-area: 1/5/5/5;
  height: 98%;

  .right-ul {
    display: flex;
    padding: 10px 22px 0 15px;
    flex-direction: column;
    max-height: 98%;
    overflow-y: scroll;

    .right-li {
      width: 94%;
      // height: 33%;
      margin-bottom: 20px;
      padding: 2% 4%;
      background-image: url("../assets/image/img-backgroud.png");

      .Title {
        font-size: 14px;
        color: white;
        padding: 4px 4px 8px 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .img {
        width: 100%;
        height: 68%;
      }

      .time,
      .name {
        font-size: 13px;
        color: white;
      }
    }
  }
}

.currentBg {
  background: rgb(0, 228, 255) !important;
}

.noCurrentBg {
  color: white !important;
}
</style>
<style lang="scss" scoped>
// 表单格式
::v-deep .el-table {
  .el-table__body {
    height: 100%;
  }
}

.el-switch__label--left {
  color: rgb(99, 124, 160) !important;
  z-index: 11111;
}

.el-switch__label--right {
  color: rgb(99, 124, 160) !important;
  z-index: 1111;
}

.el-switch__label--right.is-active {
  z-index: 1111;
  color: #fff !important;
}

.el-switch__label--left.is-active {
  z-index: 1111;
  color: #fff !important;
}

/* // 更改表头背景颜色 */
.box-container .el-table thead tr > th {
  // border: 2px solid rgb(23,133,153);
  background-color: rgb(1, 52, 92);
}

.box-container .el-table thead {
  border: 2px solid rgb(25, 169, 184) !important;
  color: white;
}

.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background-color: rgb(1, 52, 92) !important;
}

/* // 更改表格每行背景颜色 */
.box-container .el-table tbody tr > td {
  background-color: rgb(1, 20, 49);
}

.box-container .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border: none !important;
}

/* // 设置鼠标经过时背景颜色 */
.box-container .el-table tbody tr:hover > td {
  background-color: #fffff0 !important;
}

.box-container .el-table .el-table__cell {
  // border: 1px solid #999;
  padding: 5px 0;
}

.box-container .el-tabs .el-tabs__item {
  color: #a27b67 !important;
}

.box-container .el-tabs .el-tabs__item:hover {
  color: #a27b67 !important;
}

.el-tabs__active-bar {
  background-color: #a27b67 !important;
}

.box-container .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px !important;
}

.box-container .el-table__body-wrapper::-webkit-scrollbar-track-piece {
  background: rgb(1, 22, 55) !important;
}

.box-container .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background: #99a9bf;
  border-radius: 20px;
}

.box-container .el-tabs {
  width: 100%;
  color: #fffff0;
}

.center-buttom .el-tab-pane .el-image {
  width: 40px;
  height: 40px;
}

.box-container .el-tabs .el-tabs__item {
  font-weight: bolder;
  color: #fff;
}

.box-container .el-tabs__item.is-active {
  color: #409eff;
}

// Card样式
.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 50%;
  // display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.center-buttom .el-button {
  padding: 3px 4px;
}
</style>
