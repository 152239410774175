<template>
    <div class="box">
      <div class="title" v-if="showtitle">
        <div style="display: flex; justify-content: space-between; width: 100%;">
          <div class="title-word">{{ title }}</div>
          <div style="display: flex;align-items: center;color: #ffff !important;" v-if="tip">
            <div style="width: 10px;height: 10px;background-color: green;"></div><span style="margin-left: 5px;">正常</span>
            <div style="width: 10px;height: 10px;background-color: orange;margin-left: 10px;"></div><span style="margin-left: 5px;">警告</span>
            <div style="width: 10px;height: 10px;background-color: red;margin-left: 10px;"></div><span style="margin-left: 5px;">报警</span>
          </div>
        </div>
        <div  style="display: flex;align-items: center;margin-right:10px;">
          <slot name="selectBox"></slot>
        </div>
        <div style="display: flex;align-items: center;color: white">
          <div v-if="more" class="title-more">{{ more }}</div>
          <img style="width: 30px;height: 15px;" src="../assets/image/箭头.png" alt="" />
        </div>
      </div>
      <slot name="othertitle"></slot>
      <div class="chart_dec">
        <slot></slot>
      </div>
    </div>
</template>

<script>
export default {
  name: "chartbox",
  props: {
    title: {
      type: String,
      default: "标题",
    },
    more: {
      type: String,
      default: "",
    },
    tip: {
      type: Boolean,
      default: false,
    },
    showtitle: {
      type: Boolean,
      default: true,
    },
    decPad: {
      default: true,
    },
  },
  mounted() {
  },
  data() {
    return {
      state: {},
    };
  },
};
</script>

<style lang="scss" scoped>
.box {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-width: 331px;
  max-height: 99%;
  display: flex;
  padding: 5px;
  justify-content: space-evenly;
  flex-direction: column;
  background: radial-gradient(
      circle at center center,
      rgba(25, 25, 112, 0),
      rgba(25, 25, 112, 0)
    ),
    linear-gradient(rgba(179, 224, 255, 0.62), rgba(179, 224, 255, 0.62)) left
      top,
    linear-gradient(rgba(179, 224, 255, 0.62), rgba(179, 224, 255, 0.62)) left
      top,
    linear-gradient(rgba(179, 224, 255, 0.62), rgba(179, 224, 255, 0.62)) right
      top,
    linear-gradient(rgba(179, 224, 255, 0.62), rgba(179, 224, 255, 0.62)) right
      top,
    linear-gradient(rgba(108, 145, 170, 0.62), rgba(179, 224, 255, 0.62)) left
      bottom,
    linear-gradient(rgba(179, 224, 255, 0.62), rgba(179, 224, 255, 0.62)) left
      bottom,
    linear-gradient(rgba(179, 224, 255, 0.62), rgba(179, 224, 255, 0.62)) right
      bottom,
    linear-gradient(rgba(179, 224, 255, 0.62), rgba(179, 224, 255, 0.62)) right
      bottom,
    linear-gradient(rgba(56, 144, 184, 0.6), rgba(56, 144, 184, 0.6)) top,
    linear-gradient(rgba(26, 87, 130, 0.6), rgba(26, 87, 130, 0.6)) bottom,
    linear-gradient(
        rgba(56, 144, 184, 0.6),
        rgba(219, 240, 255, 0.8),
        rgba(26, 87, 130, 0.6)
      )
      left,
    linear-gradient(
        rgba(56, 144, 184, 0.6),
        rgba(219, 240, 255, 0.8),
        rgba(26, 87, 130, 0.6)
      )
      right;
  background-repeat: no-repeat;
  background-position: 1px 1px, 0px 0px, 0px 0px, 0px 100%, 0px 100%, 100% 0px,
    100% 0px, 100% 100%, 100% 100%, 0px 0px, 0px 100%, 0px 100%, 100% 100%;
  background-size: calc(100% - 2px) calc(100% - 2px), 1px 10px, 10px 1px,
    1px 10px, 10px 1px, 1px 10px, 10px 1px, 1px 10px, 10px 1px, 100% 1px,
    100% 1px, 1px 100%, 1px 100%;

  .title {
    display: flex;
    justify-content: space-between;
    line-height: 49px;
    border-radius: 10px 0px 0px 0px;
    padding-left: 16px;
    padding-right: 10px;
    background: linear-gradient(
      90deg,
      rgba(3, 173, 252, 0.3) 0%,
      rgba(4, 44, 79, 0) 99.31%
    );
    .title-slot {
      width: 180px;
      display: flex;
      justify-content: space-between;
    }

    .title-more {
      width: 45px;
      font-weight: bolder;  
    }

    // .title-img {
    //   padding-right: 10px;

    //   img {
    //     width: 3px;
    //     height: 14px;
    //   }
    // }

    .title-word {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 1px;
      color: rgba(255, 255, 255, 1);
    }
  }
}

.chart_dec {
  height: calc(100% - 60px);
  // padding: 0 16px;
}
::v-deep .el-tree-node__content:hover,
.el-upload-list__item:hover {
  background-color: #04426a;
}
::v-deep .el-tree-node:focus > .el-tree-node__content {
  background-color: #04426a !important;
}
</style>
