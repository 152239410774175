<template>
  <div class="ai-header">
    <!-- 后台按钮 -->
    <div style="display: flex">
      <div
        @click="refresh"
        style="
          color: rgb(0, 186, 255);
          font-size: 24px;
          margin-top: 37px;
          margin-right: 20px;
        "
      >
        <i class="el-icon-refresh-right" :class="{ spin: spinning }"></i
        ><span style="font-size: 20px"> 刷新</span>
      </div>
      <div
        v-if="!monitor"
        @click="handleFullScreen"
        style="color: rgb(0, 186, 255); font-size: 24px; margin-top: 37px"
      >
        <div v-if="fullscreen">
          <i class="el-icon-aim"></i>
          <span style="font-size: 20px"> 退出全屏</span>
        </div>
        <div v-else>
          <i class="el-icon-full-screen"></i
          ><span style="font-size: 20px"> 全屏</span>
        </div>
      </div>
    </div>
    <div
      class="title"
      style="color: rgb(0, 193, 255); font-weight: bolder; font-size: 38px"
    >
      {{
        monitor
          ? "优食安视频监控大屏"
          : outer || base
          ? "优食安数字食堂云平台"
          : prevention
          ? "优食安防鼠专项工作展示大屏"
          : "优食安数字食堂内控管理大屏"
      }}
    </div>
    <!-- 快捷入口  outer || (inner && AiHeaderQuery)-->
    <div
      v-if="outer || inner || base"
      style="
        position: absolute;
        right: 130px;
        top: 50px;
        color: rgb(0, 193, 255);
        font-size: 20px;
      "
      @click="showDialog"
    >
      <el-button><i class="el-icon-s-unfold"></i></el-button>
      <span style="margin-left: 10px">快捷导航</span>
    </div>

    <!-- 退出按钮 -->
    <div
      v-if="prevention || AiHeaderQuery"
      style="
        color: rgb(0, 186, 255);
        font-size: 20px;
        margin-top: 37px;
        display: flex;
        align-items: center;
      "
      @click="back"
    >
      <img
        src="../assets/image/fanhui.png"
        alt=""
        style="width: 30px; margin-right: 5px"
      />
      <span>返回</span>
    </div>
    <div
      v-else
      style="
        color: #00bada;
        font-size: 20px;
        margin-top: 37px;
        display: flex;
        align-items: center;
      "
      @click="out"
    >
      <span>退出</span>
      <img
        src="../assets/image/tuichu.png"
        alt=""
        style="width: 30px; margin-left: 2px"
      />
    </div>
    <el-dialog
      title="快捷导航"
      :visible.sync="dialogVisible"
      width="60%"
      :modal="false"
    >
      <div
        style="
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          align-items: center;
        "
      >
        <div
          class="dialogItem"
          @click="toOtherPage(item.url)"
          v-for="(item, index) in navList"
          :key="index"
        >
          <div
            class="dialogItemImg"
            :style="{ backgroundColor: backObj[item.name] }"
          >
            <img :src="item.img" alt="" style="width: 45px; height: 45px" />
          </div>
          <div>{{ item.name }}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 引入混入
import { myMixin } from "../common/common.js";
export default {
  data() {
    return {
      fullscreen: false,
      backObj: {
        防鼠大屏: "#ffcc33",
        明厨亮灶监控大屏: "#ff9966",
        店长助手大屏: "#ff6666",
        物联网监控大屏: "#cc6600",
        内控管理大屏: "#339933",
        餐厅大屏: "#339933",
        健康证展示: "#0099cc",
        产品溯源大屏: "#99cc33",
        "产品溯源大屏-ERP版": "#cccc44",
        基础版大屏: "#666699",
        整改记录大屏: "#ffcc99",
        敬请期待: "#999999",
      },
      navList: null,
      spinning: false, // 初始状态下不旋转
    };
  },
  mixins: [myMixin],
  mounted() {},
  props: {
    outer: {
      type: Boolean,
      default: false,
    },
    base: {
      type: Boolean,
      default: false,
    },
    inner: {
      type: Boolean,
      default: false,
    },
    monitor: {
      type: Boolean,
      default: false,
    },
    prevention: {
      type: Boolean,
      default: false,
    },
    AiHeaderQuery: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // 获取本地的navList
    this.navList = JSON.parse(sessionStorage.getItem("navList"));
  },
  methods: {
    // 刷新事件
    refresh() {
      this.spinning = true; // 启动旋转动画
      this.$emit("childEvent");
      setTimeout(() => {
        this.spinning = false; // 停止旋转动画
      }, 1000);
    },
    showDialog() {
      this.dialogVisible = true;
    },
    toOtherPage(url) {
      if (url) {
        this.$router.push({
          path: url,
          query: { from: "aiHeader" },
        });
        this.dialogVisible = false;
      } else {
        this.dialogVisible = false;
      }
    },
    // 全屏事件
    handleFullScreen() {
      let element = document.documentElement;
      // 判断是否已经是全屏
      // 如果是全屏，退出
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        console.log("已还原！");
      } else {
        // 否则，进入全屏
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
        console.log("已全屏！");
      }
      // 改变当前全屏状态
      this.fullscreen = !this.fullscreen;
    },
    // 退出登录
    out() {
      localStorage.clear();
      sessionStorage.clear()
      this.$router.push("login");
    },
    // 去详情页
    toTableDetail() {
      this.$router.push({ path: "/ProductEntry" });
    },
    // 返回
    back() {
      // this.$router.push({ path: "/larges" });
      window.history.back();
    },
  },
};
</script>

<style lang="scss" scoped>
/* 定义旋转动画 */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* 应用旋转动画到刷新图标 */
.el-icon-refresh-right.spin {
  animation: spin 1s linear infinite;
}
::v-deep .el-icon-s-order,
::v-deep .el-icon-s-unfold,
::v-deep .el-icon-s-home:before,
::v-deep .el-icon-s-platform:before {
  font-size: 22px;
}
::v-deep .el-button {
  padding: 1px 5px;
}
::v-deep .el-dialog {
  background-color: rgba(0, 0, 0, 0.92) !important;
}
::v-deep .el-dialog .el-button {
  height: 40px !important;
}
::v-deep .el-dialog__title {
  color: white;
  font-size: 20px;
  font-weight: bolder;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  font-size: 32px;
  color: white;
}

/* 头部 */
.ai-header {
  min-width: 1200px;
  padding: 0 25px;
  display: flex;
  align-items: center;
  // 新增
  -webkit-box-align: center;
  -webkit-box-flex: 1;
  -webkit-box-pack: center;
  display: -webkit-flex;
  //新增结束
  justify-content: space-between;
  height: 8.3%;
  background-image: url("../assets/image/header-bg.c3c4c3c7.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}


.ai-header-left {
  background: url("../assets/image/下载.png");
  width: 94px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  font-size: 18px;
  color: white;
  cursor: pointer;
  margin-top: 37px;
}

.ai-header img {
  width: 20%;
  height: 60%;
}
.dialogItem {
  display: flex;
  flex-direction: column;
  width: 30%;
  align-items: center;
  font-size: 16px;
  margin-bottom: 65px;
  color: white;
  .dialogItemImg {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #ffcc33;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
}
</style>
