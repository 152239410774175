<template>
  <!-- 全屏容器 -->
  <v-scale-screen width="1920" height="1080" fullScreen>
    <div class="container">
      <dv-border-box-11 title="整改记录大屏">
        <div class="borderBox">
          <!-- 日期 -->
          <div class="dateTime">
            {{ formattedDate }}
          </div>
          <!-- 菜单栏 -->
          <div class="menu">
            <!-- 返回按钮 -->
            <i class="el-icon-d-arrow-left" @click="goback">返回</i>
            <!-- 整改类型 -->
            <div class="warehouseCategory">
              <span class="warehouseSpan">整改类型：</span>
              <el-dropdown
                size="mini"
                split-button
                type="primary"
                trigger="click"
              >
                <span class="el-dropdown-link">
                  {{ selectValue }}
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="item in options"
                    :key="item.value"
                    @click.native.stop="changeValue1(item.label)"
                  >
                    <span> {{ item.label }}</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <!-- 日期筛选 -->
            <div class="menuTime">
              <span>日期：</span>
              <el-date-picker
                value-format="yyyy-MM-dd"
                @change="changeDate"
                v-model="value"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 380px; margin-right: 25px"
              >
              </el-date-picker>
            </div>
            <!-- 关键词筛选 -->
            <div class="block">
              <span>关键词：</span>
              <el-input
                style="width: 180px"
                @change="nameChange"
                placeholder="请输入关键词"
                v-model="requestData.search_key"
                clearable.el-button
              >
              </el-input>
            </div>
            <el-button
              style="text-align: center"
              type="primary"
              icon="el-icon-search"
              @click="search"
              >关键词查询</el-button
            >
            <el-button
              type="primary"
              icon="el-icon-refresh-right"
              @click="clearAll"
              >重置</el-button
            >
            <div class="total">整改总数：{{ total }}</div>
          </div>
          <!-- 表格 -->
          <div class="tableCom">
            <tableCom
              @customEvent="showDialog"
              :tableTitle="tableTitle"
              :tableDatas="tableSource"
              :stockHeight="stockHeight"
              :rect="true"
              class="left-three-item"
            >
              <template #images_arr="{ scope }">
                <div
                  v-if="
                    scope.row.images_arr &&
                    scope.row.images_arr.length &&
                    !scope.row.images_arr.includes('')
                  "
                  style="display: flex; align-items: center"
                >
                  <div
                    v-for="(img, index) in scope.row.images_arr"
                    :key="index"
                  >
                    <el-image
                      v-if="index < 3"
                      style="width: 60px; height: 50px; margin-right: 5px"
                      :src="img"
                      class="describleImage"
                      :preview-src-list="scope.row.images_arr"
                    ></el-image>
                  </div>
                  <span style="font-size: 12px"
                    >(共{{
                      scope.row.images_arr.length < 3
                        ? scope.row.images_arr.length
                        : 3
                    }}张)</span
                  >
                </div>
                <div v-else slot="error" class="image-slot"></div>
              </template>
            </tableCom>
          </div>
          <!-- 页码 -->
          <el-pagination
            :page-size="8"
            :current-page.sync="currentPage"
            @current-change="handleCurrentChange"
            style="position: absolute; right: 145px; bottom: 90px"
            background
            layout="prev, pager, next"
            :total="pageTotal"
          ></el-pagination>
          <!-- 整改详情 -->
          <el-dialog
            title="整改详情"
            :visible.sync="dialogVisible"
            width="45%"
            :modal="false"
          >
            <div class="rectifItem">
              <div class="shopName">门店：{{ dialogData.shopName }}</div>
              <div class="requirement">标题：{{ dialogData.title }}</div>
              <div class="Initiator">发起人：{{ dialogData.created_user }}</div>
              <div class="acceptedBy">
                受理人：{{ dialogData.operation_user }}
              </div>
              <div class="InitiationTime">
                发起时间：{{ dialogData.created_time }}
              </div>
              <div class="InitiationDesc">
                问题描述：{{ dialogData.problem_desc }}
              </div>
              <div class="Deadline">
                要求完成时间：{{ dialogData.hope_done_time }}
              </div>
              <div class="Deadline">
                当前状态：{{
                  dialogData.status == 1
                    ? "进行中"
                    : dialogData.status == 3
                    ? "已逾期"
                    : "已完成"
                }}
              </div>
            </div>
            <div
              style="
                display: flex;
                margin-top: 30px;
                justify-content: space-between;
              "
            >
              <div class="result">
                <!-- images_arr -->
                <div class="resultDesc">
                  <img
                    src="../assets/image/isdue.png"
                    style="width: 20px; margin-right: 4px"
                  />整改问题：
                </div>
                <div class="resultPic" style="display: flex; margin-top: 30px">
                  <div v-for="item in dialogData.images_arr" :key="item">
                    <el-image
                      class="describleImage"
                      :src="item"
                      :preview-src-list="dialogData.images_arr"
                    ></el-image>
                  </div>
                </div>
              </div>
              <div class="result">
                <div class="resultDesc">
                  <img
                    src="../assets/image/wancheng.png"
                    style="width: 20px; margin-right: 4px"
                  />整改反馈：
                </div>
                <div class="resultPic" v-if="dialogData.status == '2'">
                  <div style="display: flex; margin-bottom: 5px">
                    <div v-for="item in dialogData.feedback_images" :key="item">
                      <el-image
                        class="describleImage"
                        :src="item"
                        :preview-src-list="dialogData.feedback_images"
                      ></el-image>
                    </div>
                  </div>
                  <div style="margin-bottom: 5px">
                    整改完成时间：{{ dialogData.feedback_user_time }}
                  </div>
                  <div>整改人：{{ dialogData.feedback_user }}</div>
                </div>
                <div
                  else
                  v-else-if="dialogData.status == '1'"
                  style="font-weight: bolder"
                >
                  暂无整改记录
                </div>
                <div v-else style="color: red; font-weight: bolder">
                  已逾期!
                </div>
              </div>
            </div>
          </el-dialog>
        </div>
      </dv-border-box-11>
    </div>
  </v-scale-screen>
</template>
<script>
import ChartBox from "../components/chartBox.vue";
import Chart from "../components/chart.vue";
import { getProblemCat, getProblemList } from "../api/api";
import { baseImgUrl } from "../utils/request";
// 引入混入
import { myMixin } from "../common/common.js";

export default {
  data() {
    return {
      options: [],
      selectValue: "",
      dialogVisible: false,
      total: "",
      stockHeight: 760,
      currentPage: 1,
      pageTotal: 0,
      requestData: {
        start_date: "",
        end_date: "",
        page: 1,
        search_key: "",
        cat_name: "",
        from: "",
      },
      value: "",
      tableSource: [],
      tableTitle: [],
      dialogData: {},
    };
  },
  mixins: [myMixin],
  methods: {
    showDialog(data) {
      this.dialogVisible = true;
      this.dialogData = data;
      this.dialogData.shopName = localStorage.getItem("shop_name");
    },
    clearAll() {
      this.requestData = {
        start_date: "",
        end_date: "",
        page: 1,
        search_key: "",
        cat_name: "",
        from: "",
      };
      this.value = "";
      this.changeValue1("", "全部");
    },
    changeValue1(e, isAll) {
      if (isAll === "全部") {
        this.requestData.cat_name = "";
        this.selectValue = isAll;
      } else {
        this.selectValue = e;
        if (e === "全部") {
          this.requestData.cat_name = "";
        } else {
          this.requestData.cat_name = e;
        }
      }
      this.changeDates = false;
      this.getProblemListData();
      this.requestData.page = 1;
      this.requestData.search_key = "";
      this.tableTitle = [
        {
          name: "类型",
          width: "180",
          value: "type",
        },
        {
          name: "标题",
          width: "200",
          value: "title",
        },

        {
          name: "发起时间",
          width: "",
          value: "created_time",
        },
        {
          name: "发起人",
          width: "",
          value: "created_user",
        },
        {
          name: "受理人",
          width: "",
          value: "operation_user",
        },

        {
          name: "问题图片",
          width: "260",
          value: "images_arr",
        },
        {
          name: "整改完成时间",
          width: "200",
          value: "feedback_user_time",
        },
        {
          name: "状态",
          width: "",
          value: "status",
        },
      ];
      setTimeout(() => {
        this.changeDates = true;
      });
      this.currentPage = 1;
    },
    handleCurrentChange(val) {
      this.requestData.page = val;
      this.getProblemListData();
      this.requestData.page = 1;
    },
    search() {
      this.getProblemListData();
    },
    // 健康证搜索框change事件
    nameChange(e) {
      this.requestData.search_key = e;
    },
    // 时间选择器change事件
    changeDate(e) {
      this.requestData.start_date = e[0];
      this.requestData.end_date = e[1];
      this.getProblemListData();
    },
    async getProblemCatData() {
      const res = await getProblemCat();
      this.options = res.data.data[0].map((obj, index) => ({
        label: obj.name, // 将字段名为 name 的键改为 label
        value: String(index + 1), // 添加一个键值对，键名为 value，值为索引值加一
      }));
      this.options.unshift({ label: "全部", value: "" });
    },
    async getProblemListData() {
      const res = await getProblemList(this.requestData);
      this.total = res.data.data[0].total;
      this.tableSource = res.data.data[0].data.map((item) => {
        const updatedImagesArr = item.images_arr.map((key) => {
          return key.includes("http") ? key : baseImgUrl + key;
        });
        if (!item.feedback_images) {
          console.log("item.feedback_images is null");
        } else {
          // 将逗号分隔的字符串转换为数组
          var imagesArray = item?.feedback_images.split(",");
          // 遍历数组，判断每个元素是否包含http，如果不包含则添加基础路径
          var feedbackImages = imagesArray.map((image) => {
            return image.includes("http") ? image : baseImgUrl + image;
          });
        }

        return {
          ...item,
          images_arr: updatedImagesArr,
          feedback_images: feedbackImages,
          created_time: item.created_time.slice(0, 16),
        };
      });
      this.pageTotal = res.data.data[0].total;
    },
  },
  components: { ChartBox, Chart },
  created() {
    this.clearAll();
    this.getProblemCatData();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.container {
  width: 1920px;
  height: 1080px;
  background: url("../assets/image/bg.577ccc4c.jpg");
  .dateTime {
    color: white;
    position: absolute;
    top: 80px;
    right: 150px;
    font-weight: bolder;
    font-size: 18px;
  }
  .menu {
    height: 13%;
    display: flex;
    align-items: flex-end;
    padding: 20px 155px 40px;
    i {
      color: aliceblue;
      font-size: 20px;
      position: absolute;
      top: 80px;
      left: 35px;
    }
    .warehouseCategory {
      .warehouseSpan {
        color: white;
        font-size: 14px;
        font-weight: bolder;
        display: inline-block;
        width: 80px;
      }
    }
    .block {
      display: flex;
      align-items: center;
      margin-right: 20px;
      span {
        color: white;
        font-weight: bolder;
      }
    }
    .menuTime {
      color: white;
      margin-left: 20px;
      font-weight: bolder;
    }
    .total {
      font-size: 16px;
      color: gray;
      font-weight: bolder;
      position: absolute;
      right: 150px;
    }
  }
  .tableCom {
    width: 85%;
    margin: 0 auto;
    height: 85%;
  }
  .borderBox {
    height: 100%;
    width: 100%;
  }
}
.rectifItem {
  font-weight: bolder;
  padding: 5px 20px;
  background-color: rgb(236, 248, 255);
  border-radius: 5px;
  .InitiationDesc {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 72px; /* 设置最大高度，以便在文本超过三行时显示省略号 */
  }
  div {
    margin: 15px 0;
    font-size: 16px;
  }
}
.result {
  width: 42%;
  padding: 10px 20px 20px 20px;
  background-color: #e7f7e7;
  border-radius: 5px;
  font-weight: bolder;
  .resultDesc {
    margin: 8px 0 15px 0;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  .resultPic {
    .describleImage {
      margin-right: 15px;
      width: 80px;
      height: 80px;
      border-radius: 5px;
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table th.el-table__cell.is-leaf {
  height: 40px;
  border: none !important;
  color: rgb(64, 158, 255);
  font-size: 18px;
  background-color: rgb(236, 248, 255);
}
::v-deep .el-table td.el-table__cell {
  background-color: white !important;
  border-bottom: 1px solid rgb(240, 241, 243) !important;
  font-size: 16px;
}
// 弹窗
::v-deep .el-dialog {
  height: 680px !important;
}
::v-deep .el-dialog__title {
  font-size: 20px;
  font-weight: bolder;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  font-size: 32px;
}
::v-deep .el-button--mini {
  font-size: 15px;
}
::v-deep .el-button {
  padding: 12px 10px;
}
::v-deep .el-button--small {
  font-size: 16px;
}

.el-dropdown-menu--mini .el-dropdown-menu__item {
  font-size: 15px;
  color: #999;
}

.container .el-table__body-wrapper::-webkit-scrollbar {
  width: 0.375rem !important;
}
// 时间选择器样式
.container .el-range-editor.el-input__inner {
  background-color: rgb(1, 52, 92);
  color: white;
}
::v-deep .container .el-range-editor span {
  color: white;
  font-weight: bolder;
  margin: 0 10px;
}
::v-deep .container .el-date-editor .el-range-input {
  border-radius: 5px;
  margin-left: 5px;
}
</style>
