<template>
  <!-- 全屏容器 -->
  <v-scale-screen
    width="1920"
    height="1080"
    fullScreen
    style="background-color: rgb(1, 21, 52)"
  >
    <div
      style="
        color: rgb(0, 186, 255);
        font-size: 20px;
        margin-top: 50px;
        margin-bottom: 80px;
        display: flex;
        align-items: center;
      "
      @click="back"
    >
      <img
        src="../assets/image/fanhui.png"
        alt=""
        style="width: 30px; margin-right: 5px"
      />
      <span>返回</span>
    </div>
    <div
      style="
        position: absolute;
        top: 40px;
        left: 140px;
        color: white;
        font-size: 35px;
        display: flex;
        align-items: center;
      "
    >
      <img
        :src="shop_img"
        style="
          width: 100px;
          height: 100px;
          border-radius: 50%;
          margin-right: 20px;
        "
      />
      <span>店长助手 —— 每日任务自检清单</span>
    </div>
    <!-- 日期选择器 -->
    <div style="position: absolute; top: 40px; right: 140px; color: white">
      <span class="demonstration" style="margin-right: 20px">选择日期</span>
      <el-date-picker
        v-model="search_day"
        align="right"
        type="date"
        placeholder="选择日期"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
    </div>
    <div
      class="block"
      style="
        padding: 10px 20px 0px 0px;
        overflow-y: scroll;
        height: 860px;
        width: calc(100% - 280px);
        margin:auto;
      "
    >
      <el-timeline v-for="(item, index) in taskList" :key="index">
        <el-timeline-item
          :timestamp="item.time"
          placement="top"
          icon="el-icon-s-claim"
        >
          <h4
            style="
              height: 40px;
              line-height: 40px;
              display: flex;
              justify-content: space-between;
            "
          >
            <span style="color: rgb(251, 192, 147); font-size: 22px">{{
              item.group_name
            }}</span>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <span
                :style="{
                  backgroundColor: backObj[item.task_status_name] || '',
                }"
                class="status"
              >
                <img
                  src="../assets/image/wancheng.png"
                  alt=""
                  style="width: 30px; margin-right: 5px"
                  v-if="item.task_status_name == '已完成'"
                />
                <img
                  src="../assets/image/chaoshi.png"
                  alt=""
                  style="width: 30px; margin-right: 5px"
                  v-if="item.task_status_name == '已逾期'"
                />
                <img
                  src="../assets/image/ing.png"
                  alt=""
                  style="width: 30px; margin-right: 5px"
                  v-if="item.task_status_name == '进行中'"
                />
                <img
                  src="../assets/image/jiezhi.png"
                  alt=""
                  style="width: 30px; margin-right: 5px"
                  v-if="item.task_status_name == '已截止'"
                />
                <img
                  src="../assets/image/daizhixing.png"
                  alt=""
                  style="width: 30px; margin-right: 5px"
                  v-if="item.task_status_name == '待执行'"
                />
                {{ item.task_status_name }}</span
              >
              <span
                @click="handleClick(index)"
                style="
                  color: rgb(247, 239, 232);
                  font-size: 14px;
                  margin-left: 20px;
                  cursor: pointer;
                "
                >{{ openCards.includes(index) ? "折起" : "展开" }}
                <i
                  class="el-icon-caret-bottom"
                  v-if="!openCards.includes(index)"
                ></i
                ><i class="el-icon-caret-top" v-else></i
              ></span>
            </div>
          </h4>
          <el-card>
            <div
              :style="{
                display: !openCards.includes(index) ? 'block' : 'none',
              }"
            >
              <el-button
                type="warning"
                icon="el-icon-s-unfold"
                @click="handleClick(index)"
                >更多</el-button
              >
            </div>
            <el-row :gutter="35" :class="{ active: openCards.includes(index) }">
              <el-col :span="8" v-for="(o, inde) in item.task" :key="item.id">
                <div class="cardWrap">
                  <el-card :body-style="{ padding: '10px' }">
                    <div
                      style="
                        margin-right: 50px;
                        font-weight: bolder;
                        width: 100%;
                        font-size: 16px;
                      "
                    >
                      <div class="title" style="margin-bottom: 30px">
                        <div class="describleIndex">0{{ inde + 1 }}</div>
                        <span
                          style="font-size: 18px; color: rgb(230, 126, 34)"
                          >{{ o.assess_item_name }}</span
                        >
                      </div>
                      <ul>
                        <div
                          v-for="(k, ind) in o.arr_score_content"
                          :key="k.value"
                          style="display: flex; justify-content: space-between"
                        >
                          <li style="margin-bottom: 20px">{{ k.value }}</li>
                          <el-checkbox v-model="k.checked">符合</el-checkbox>
                        </div>
                        <div
                          v-for="(i, is) in o.arr_photo_url"
                          :key="is"
                          style="display: inline-block"
                        >
                          <div v-if="i">
                            <el-image
                              :src="i"
                              :preview-src-list="o.arr_photo_url"
                              style="
                                width: 100px;
                                height: 100px;
                                border-radius: 5px;
                                margin: 15px;
                              "
                            ></el-image>
                          </div>
                        </div>
                      </ul>
                    </div>
                  </el-card>
                </div>
              </el-col>
            </el-row>
          </el-card>
        </el-timeline-item>
      </el-timeline>
      <img
        v-if="taskList"
        src="../assets/image/end.png"
        style="margin-left: 212px; width: 30px"
      />
      <img
        v-else
        style="width: 400px;margin: 250px 0 0 600px;"
        src="./../assets/image/NoImg.png"
        alt=""
      />
    </div>
  </v-scale-screen>
</template>
<script>
import { getTaskRecordV2 } from "../api/api";
import { baseImgUrl } from "../utils/request";
// 引入混入
import { myMixin } from "../common/common.js";

export default {
  data() {
    return {
      backObj: {
        已完成: "rgb(201, 242, 155)",
        待执行: "rgb(227, 186, 143)",
        已逾期: "rgb(255, 148, 112)",
        已截止:"rgb(255, 148, 112)",
        进行中:"(249, 180, 45)",
      },
      taskList: [],
      currentIndex: 0,
      search_day: new Date(),
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      openCards: [0, 1,2,3,4,5,6,7,8,9], // 存储当前展开的card索引
    };
  },
  mixins: [myMixin],
  methods: {
    handleClick(index) {
      if (this.openCards.includes(index)) {
        this.openCards = this.openCards.filter((item) => item !== index);
      } else {
        this.openCards.push(index);
      }
    },
    back() {
      window.history.back();
    },
    async getTaskRecordV2Date() {
      const res = await getTaskRecordV2({
        store_id: localStorage.getItem("store_id"),
        search_day: this.searchDay || this.search_day,
      });
      /// 假设res.data.data.taskList是您的数组
      this.taskList = res.data.data.taskList;

      if (this.taskList) {
        // 使用map方法对数组中的每个对象进行处理
        this.taskList.forEach((item) => {
          // 判断time字段是否存在并且长度大于等于6
          if (item.time && item.time.length >= 6) {
            // 截取第6位到最后的字符串并直接赋值给原数组
            item.time = item.time.substring(5);
          }
        });

        // 使用forEach方法遍历taskList数组中的每个对象
        this.taskList.forEach((item) => {
          // 遍历每个对象的tasks数组
         if(item.task){
          item.task.forEach((t) => {
            // 将arr_score_content字符串数组转换为对象数组，并添加checked字段
            t.arr_score_content = t.arr_score_content.map((score) => {
              return { value: score, checked: true };
            });
          });
         }
        });
      }
    },
  },
  computed: {
    activeClass() {
      return { active: this.currentIndex !== null };
    },
  },
  watch: {
    search_day: {
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.getTaskRecordV2Date();
        }
      },
      immediate: true,
    },
  },
  computed: {
    searchDay() {
      const dateTimeString = this.search_day || new Date();
      const date = new Date(dateTimeString);
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // 月份是从0开始计数的，需要加1
      const day = date.getDate();
      const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${
        day < 10 ? "0" + day : day
      }`;
      return formattedDate;
    },
  },
  created() {
    this.getTaskRecordV2Date();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.cardWrap {
  width: 100%;
  height: 100%;
  padding: 17.5px 0px;
}
::v-deep .el-timeline-item__wrapper {
  padding-left: 255px;
}
::v-deep .el-timeline-item__node--normal {
  width: 0;
  left: 12px;
  top: 2px;
}

::v-deep .el-timeline-item__timestamp {
  position: absolute;
  left: 0px;
  color: rgb(251, 192, 147);
  font-size: 22px;
}
// 线
::v-deep .el-timeline .el-timeline-item:last-child .el-timeline-item__tail {
  display: block !important;
  position: absolute;
  left: 225px;
  top: 18px;
  border-left: 1px solid #e4e7ed;
}
// 图标
::v-deep .el-timeline-item__icon {
  position: absolute;
  font-size: 22px;
  left: 203px;
  z-index: 9999;
}

.el-timeline-item .el-card .el-row:not(.active) {
  display: none !important;
}

.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  height: 150px;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
.describleIndex {
  display: inline-block;
  width: 30px;
  height: 25px;
  line-height: 25px;
  background-color: rgb(249, 180, 45);
  color: white;
  font-size: 15px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  text-align: center;
  margin-right: 10px;
}
body {
  background: url("../assets/image/bg.577ccc4c.png");

  /* 自定义滚动条样式 */
  .block::-webkit-scrollbar {
    width: 8px; /* 设置滚动条宽度 */
  }

  .block::-webkit-scrollbar-track {
    background: rgb(1, 22, 55) !important;
  }

  .block::-webkit-scrollbar-thumb {
    background: #99a9bf;
    border-radius: 20px;
  }
}
.status {
  display: flex;
  align-items: center;
  padding: 2px 8px;
  color: rgb(1, 21, 52);
  border-radius: 8px;
  height: 30px;
}
</style>
