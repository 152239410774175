import axios from "axios";
// 引入路由
import router from "../router";
var baseUrl='https://aishop2.ufoodlife.cn'
var baseImgUrl='https://swysa.oss-cn-hangzhou.aliyuncs.com'



const http = axios.create({
  //http://aishop2.ufoodlife.cn
  //通用请求地址（导航前缀）
  baseURL: baseUrl,
  timeout: 20000, //超时时间
});


// 添加请求拦截器
http.interceptors.request.use(
  function (config) {
    if (sessionStorage.getItem("token")) {
      config.headers = {
        token: sessionStorage.getItem("token"),
      };
    }

    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
http.interceptors.response.use(
  function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    return response;
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export  {http,baseUrl,baseImgUrl};
