<template>
  <div class="swiper-container">
    <div v-if="backgroundImageUrl.length == 0">
      <img
        style="height: 550px; width: 100%"
        src="./../assets/image/NoImg.png"
        alt=""
      />
    </div>
    <div class="swiper-wrapper" v-else>
      <div
        v-for="(image, index) in backgroundImageUrl"
        :key="index"
        class="swiper-slide"
      >
        <!-- <el-image
        v-for="(photoUrl, index) in backgroundImageUrl"
          style="width: 100%; height: 100%;"
          :key="index"
          :src="photoUrl"
          :preview-src-list="backgroundImageUrl"
        ></el-image> -->
        <!-- <Img :src="image" style="width: 100%; height: 100%"></Img> -->
         <!-- 静态 -->
        <Img :src="image.src" style="width: 100%; height: 100%"></Img>
      </div>
    </div>
    <!-- <div class="swiper-pagination swiper-pagination-white"></div>
    <div class="swiper-button-next swiper-button-white"></div>
    <div class="swiper-button-prev swiper-button-white"></div> -->
  </div>
</template>

<script>
import Swiper from "swiper";

export default {
  data() {
    return {};
  },
  props: {
    backgroundImageUrl: {
      type: Array,
      required: true,
    },
  },
  name: "SwiperComponent",
  mounted() {
    this.swiper = new Swiper(".swiper-container", {
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      loop: true,
      spaceBetween: 30,
      effect: "coverflow",
      coverflowEffect: {
        rotate: 70,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      },
      speed: 900, // 调整动画速度，单位为毫秒
      // pagination: {
      //   el: '.swiper-pagination',
      //   clickable: true,
      // },
      // navigation: {
      //   nextEl: ".swiper-button-next",
      //   prevEl: ".swiper-button-prev",
      // },
    });
  },
  beforeDestroy() {
    this.swiper.destroy();
  },
};
</script>

<style>
html,
body {
  position: relative;
  height: 100%;
}
body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}
.swiper-container {
  width: 100%;
  height: 100%;
  /* margin-top: 5px; */
}
.swiper-slide {
  /* background-position: center;
  background-size: cover; */
}
</style>
