<template>
  <!-- 全屏容器 -->
  <v-scale-screen width="1920" height="1080" fullScreen>
    <div class="container">
      <dv-border-box-11 title="每日自检任务清单">
        <div class="borderBox">
          <!-- 返回 -->
          <div
            style="
              height: 8%;
              display: flex;
              align-items: flex-end;
              padding: 20px 155px 40px;
            "
          >
            <i
              class="el-icon-d-arrow-left"
              style="
                color: aliceblue;
                font-size: 20px;
                position: absolute;
                top: 80px;
                left: 35px;
              "
              @click="goneikong"
              >返回</i
            >
          </div>
          <!-- 日期选择器 -->
          <div
            class="block"
            style="position: absolute; top: 80px; right: 70px; color: white"
          >
            <span class="demonstration" style="margin-right: 20px"
              >选择日期</span
            >
            <el-date-picker
              v-model="search_day"
              align="right"
              type="date"
              placeholder="选择日期"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </div>
          <el-tabs
            type="border-card"
            style="width: 1750px; margin: 0 auto; height: 80%"
            @tab-click="handleTabClick"
          >
            <el-tab-pane
              v-for="item in getTaskTemplateList"
              :key="item.task_template_id"
              :label="item.name"
            >
              <el-collapse
                v-model="activeName"
                :accordion="true"
                @change="handleCollapseChange"
              >
                <el-collapse-item
                  v-for="(item, index) in firstLevelMenu"
                  :key="index"
                  :name="index"
                >
                  <template slot="title">
                    <img
                      src="../assets/image/task3.png"
                      style="margin: 0 8px; width: 15px"
                    /><span style="font-weight: bolder; font-size: 16px">{{
                      item.title
                    }}</span>
                  </template>
                  <div v-if="Object.keys(secondContent).length !== 0">
                    <div class="taskTitle" style="font-size: 15px">
                      <div class="taskIcon"></div>
                      <div>{{ secondContent.group_name }}</div>
                    </div>
                    <div
                      class="taskContent"
                      v-for="(item, index) in secondContent.task"
                      :key="index"
                    >
                      <div class="describle">
                        <div>
                          <div style="display: flex">
                            <div class="describleIndex">0{{ index + 1 }}</div>
                            <div>{{ item.describle }}</div>
                          </div>
                          <!-- 大图预览 -->
                          <div v-if="item.arr_photo_url">
                            <el-image
                              v-for="(photoUrl, index) in item.arr_photo_url"
                              :key="index"
                              class="describleImage"
                              :src="photoUrl"
                              :preview-src-list="item.arr_photo_url"
                            ></el-image>
                          </div>
                          <el-image
                            v-else
                            class="describleImage"
                            :src="item.phone_url"
                            :preview-src-list="[item.phone_url]"
                          ></el-image>
                        </div>
                        <div
                          style="
                            margin-right: 50px;
                            font-weight: bolder;
                            width: 400px;
                            font-size: 16px;
                          "
                        >
                          <ul
                            v-for="(item, index) in item.arr_score_content"
                            :key="index"
                            style="
                              display: flex;
                              justify-content: space-between;
                            "
                          >
                            <li style="margin-bottom: 5px">{{ item }}</li>
                            <el-checkbox v-model="checked">符合</el-checkbox>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="Object.keys(secondContent).length === 0"
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <img
                      src="../assets//image/NoImg.png"
                      style="width: 350px; height: 260px"
                    />
                  </div>
                </el-collapse-item>
              </el-collapse>
            </el-tab-pane>
          </el-tabs>
        </div>
      </dv-border-box-11>
    </div>
  </v-scale-screen>
</template>
<script>
import ChartBox from "../components/chartBox.vue";
import Chart from "../components/chart.vue";
import { getTaskTemplate, getTaskAssess, getTaskRecord } from "../api/api";
// 引入混入
import { myMixin } from "../common/common.js";

export default {
  data() {
    return {
      checked: true,
      // 查看大图需要的参数
      isShowImg: false,
      getSrc: "",
      dataList: [],
      imgIndex: undefined,
      getTaskTemplateList: [],
      activeName: [0],
      firstLevelMenu: [],
      secondContent: {},
      search_day: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      firstId: "",
      srcList: [], //多张图数组列表
    };
  },
  mixins: [myMixin],
  computed: {
    searchDay() {
      const dateTimeString = this.search_day || new Date();
      const date = new Date(dateTimeString);
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // 月份是从0开始计数的，需要加1
      const day = date.getDate();
      const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${
        day < 10 ? "0" + day : day
      }`;
      return formattedDate;
    },
  },
  methods: {
    // 获取一级菜单
    async getTaskTemplateDate() {
      const res = await getTaskTemplate({
        store_id: localStorage.getItem("store_id"),
      });
      // 假设res.data.data是一个包含多个对象的数组
      const extractedData = res.data.data.map((item) => ({
        name: item.name,
        task_template_id: item.task_template_id,
      }));
      this.getTaskTemplateList = extractedData;
    },
    // 获取二级菜单
    async getTaskFirst(id) {
      if (this.firstLevelMenu.length != 0) {
        this.firstLevelMenu = [];
      }
      const res = await getTaskAssess({ task_template_id: id });
      res.data.data.forEach((item) => {
        this.firstLevelMenu.push({
          title: item.assess_name,
          assess_id: item.assess_id,
        });
      });
      if (this.firstId == "") {
        this.firstId = this.firstLevelMenu[0].assess_id;
      }
    },
    // 点击一级菜单，展开对应的页面
    handleTabClick(tab) {
      this.firstId = "";
      // 在这里处理点击事件，获取二级菜单的数据
      this.getTask(tab.index);
    },
    // 获取二级菜单的数据
    getTask(index) {
      const id = this.getTaskTemplateList[index].task_template_id;
      this.getTaskFirst(id);
    },
    // 点击二级菜单，跳转到对应的页面
    handleCollapseChange(val) {
      const clickedAssessId = this.firstLevelMenu[val].assess_id;
      this.firstId = clickedAssessId;
      this.getTaskRecordData(clickedAssessId);
    },
    // 获取二级菜单数据
    async getTaskRecordData(id) {
      const res = await getTaskRecord({
        assess_id: id,
        store_id: localStorage.getItem("store_id"),
        search_day: this.searchDay || this.search_day,
      });
      // 假设res.data.data是包含数据的对象
      if (res.data.code === 0) {
        this.secondContent = {};
      } else {
        this.secondContent = Object.values(res?.data.data)[0];
      }
    },
    goneikong() {
      window.history.back();
    },
  },
  watch: {
    getTaskTemplateList: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal.length > 0) {
          this.getTask(0);
        }
      },
      immediate: true,
    },
    firstId: {
      handler: function (newVal, oldVal) {
        if (newVal && oldVal == "") {
          this.getTaskRecordData(this.firstId);
        }
      },
      immediate: true,
    },
    search_day: {
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.getTaskRecordData(this.firstId);
        }
      },
      immediate: true,
    },
  },
  components: { ChartBox, Chart },
  mounted() {
    this.getTaskTemplateDate();
  },
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.container {
  width: 1920px;
  height: 1080px;
  background: url("../assets/image/bg.577ccc4c.png");
  .borderBox {
    width: 100%;
    height: 100%;
    .taskTitle {
      display: flex;
      height: 50px;
      align-items: center;
      padding: 0 10px;
      .taskIcon {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        border: 3px solid rgb(72, 148, 244);
        margin-right: 8px;
      }
    }
    .taskContent {
      background-color: rgb(242, 246, 249);
      padding: 20px;
      .describle {
        padding-top: 20px;
        height: 190px;
        border-radius: 10px;
        background-color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;

        .describleIndex {
          width: 30px;
          height: 25px;
          background-color: rgb(20, 194, 154);
          color: white;
          font-size: 14px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          text-align: center;
          margin-right: 10px;
        }

        .describleImage {
          width: 100px;
          height: 100px;
          border-radius: 10px;
          margin-left: 40px;
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-tabs--border-card > .el-tabs__header {
  background-color: transparent;
  border-radius: 5px;
  border-color: transparent;
}
::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item {
  font-weight: bolder;
}
::v-deep .el-tabs--border-card {
  border-image-width: initial;
  //border-image-source: url(../assets/image/border.png);
  // border-image-source: url(../assets/image/boxBG.png);
  border-image-slice: 40 fill;
  border-image-repeat: stretch;
  border-image-outset: 2px;
  border-width: 20px;
  border-style: solid;
  border-color: transparent;
  background-color: transparent;
  color: white;
}
::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item {
  // width: 78px;
  height: 40px;
  padding: 1px 15px 1px 15px;
  line-height: 38px;
  text-align: center;
  font-size: 16px;
  color: rgb(191, 230, 255);
  background-color: #062e44;
  border-radius: 2px;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  opacity: var(--dasv-button-opacity);
  transition: opacity 0.2s, background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  white-space: nowrap;
  word-break: break-all;
  word-wrap: break-word;
  margin-right: 10px;
}
::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  background-color: transparent;
  box-shadow: 0px 0px 3px rgba(102, 203, 255, 1) inset,
    0px 0px 7px rgba(51, 185, 255, 1) inset,
    0px 0px 10px rgba(0, 168, 255, 1) inset;
  border: none;
}
// 折叠板样式
::v-deep .el-tabs--border-card > .el-tabs__content {
  height: 800px;
  overflow-y: scroll;
  padding: 15px 0;
}
::v-deep .el-collapse-item__header {
  height: 60px;
  border: none;
}

// 滚动条样式
::v-deep .container .el-tabs__content::-webkit-scrollbar {
  width: 6px !important;
}

::v-deep .container .el-tabs__content::-webkit-scrollbar-track-piece {
  background: rgb(1, 22, 55) !important;
}

::v-deep .container .el-tabs__content::-webkit-scrollbar-thumb {
  background: #99a9bf;
  border-radius: 20px;
}
::v-deep .el-checkbox__label {
  font-size: 16px;
}
</style>
