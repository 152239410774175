export var myMixin = {
  data() {
    return {      
      rolltimer: undefined,//表格滚动定时器
      currentDate: new Date(),//日期
      // 食堂信息
      shop_name: "",
      shop_users_amount: "",
      shop_owner: "",
      shop_phone: "",
      shop_img: "",
      shop_address: "",
      area:'',
      users_amount:'',
      dialogVisible: false,
      // 视频监控
      defaultImgB: "",
      isCarousel: true,
      value2: "",
      option2: [],
      currentImg: "",
      checked: false, //视频流是否开启
    };
  },
  methods: {
    handleBImageError() {
      this.defaultImgB = "http://aibigscreen.ufoodlife.cn/img/nosignal2.png"; // 图片加载错误时，将src属性绑定到默认的无信号图片路径
    },//获取iframe后给iframe发送消息
    sendIframeMessage(url) {
      var iframe = document.getElementById("iframe");
      setTimeout(() => {
        if (iframe) {
          iframe.contentWindow.postMessage(
            {
              type: "videoUrl",
              videoUrl: url,
            },
            "*"
          );
        }
      }, 500);
      // 确保iframe存在
    },   
    // 视频监控点击事件
    changeValue2(text) {
      this.isCarousel = false;
      this.value2 = text;
      // 查找label为"text"的对象
      let foundObject = this.option2.find((obj) => obj.label === text);

      if (foundObject) {
        // 根据data中checked的值来决定赋值给currentImg的属性
        this.currentImg = this.checked
          ? foundObject.flv_url
          : foundObject.channel_image;
      }
      this.sendIframeMessage(this.currentImg);
    },
    // 开始滚动
    startScroll() {
      this.tableScroll(false);
    },
    // 结束滚动
    stopScroll() {
      this.tableScroll(true);
    },
    // 滚动方法
    tableScroll(stop) {
      if (stop) {
        clearInterval(this.rolltimer);
        return;
      }

      const table = this.$refs.rolltable;
      const divData = table?.bodyWrapper;

      if (this.rolltimer) {
        clearInterval(this.rolltimer);
      }

      const scrollFunction = () => {
        if (divData && divData.scrollTop !== undefined) {
          divData.scrollTop += 1;
          if (
            divData.clientHeight + divData.scrollTop + 1 >
            divData.scrollHeight
          ) {
            if (table.tableData.length > 5) {
              divData.scrollTop = 1;
              clearInterval(this.rolltimer);
              this.tableScroll(false);
            }
          }
        }
      };
      this.rolltimer = setInterval(scrollFunction, 40);
    },
    // 返回方法
    goback() {
      window.history.back();
    },
    // 去温湿度折线图页面
    goToTemperHumidity() {
      this.$router.push({ path: "/IoTMonitoring" });
      this.dialogVisible = false;
    },
    // 跳转到健康证查询页面
    goToHealthyList() {
      this.$router.push({ path: "/healthyList" });
      this.dialogVisible = false;
    }, 
    //餐厅大屏去监控页面
    toMonitor() {
      this.$router.push({ path: "/VideoMonitor" });
    },
  },
  computed: {
    // 日期
    formattedDate: function () {
      var year = this.currentDate.getFullYear();
      var month = this.currentDate.getMonth() + 1;
      var day = this.currentDate.getDate();
      var weekdays = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      var weekday = weekdays[this.currentDate.getDay()];
      return year + "年" + month + "月" + day + "日 " + weekday;
    },
  },
  mounted() {    
    this.shop_owner=localStorage.getItem('shop_owner')
    this.shop_img=localStorage.getItem('shop_img')
    this.shop_name=localStorage.getItem('shop_name')
    this.shop_phone=localStorage.getItem('shop_phone')
    this.shop_users_amount=localStorage.getItem('shop_users_amount')
    this.shop_address=localStorage.getItem('shop_address')
    this.area=localStorage.getItem('area')
    this.users_amount=localStorage.getItem('users_amount')  
    setTimeout(() => {
      this.tableScroll(false);
    }, 1000);
  },
    
};
