<template>
  <!-- 全屏容器 -->
  <v-scale-screen width="1920" height="1080" fullScreen>
    <div class="container">
      <!-- 查看大图容器 -->
      <div class="imgwrap" v-if="isShowImg">
        <img class="next" src="../assets/image/next.png" @click="nextImage()" />
        <img class="up" src="../assets/image/up.png" @click="upImage()" />
        <img :src="bigPic" alt="点击关闭" @click="viewBack" />
      </div>
      <!-- 标题栏部分 -->
      <AiHeader
        ref="mychild"
        :base="true"
        :AiHeaderQuery="AiHeaderQuery"
        @childEvent="handleChildEvent"
      ></AiHeader>
      <div class="box-container">
        <aside>
          <!-- 学校信息 -->
          <div class="box-left-one">
            <ChartBox title="食堂信息">
              <div
                class="logo"
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-top: 20px;
                "
              >
                <img
                  :src="shop_img"
                  style="
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    margin-left: 5px;
                    margin-top: 50px;
                  "
                />
                <div style="color: white">
                  <div
                    class="phone"
                    style="margin: 10px 0; width: 240px; font-weight: bolder"
                  >
                    <span>{{ shop_name }}</span>
                  </div>
                  <div class="phone" style="margin: 10px 0">
                    <span>负 责 人 ：</span>
                    <span>{{ shop_owner }}</span>
                  </div>
                  <div class="phone" style="margin-bottom: 10px">
                    <span>联系电话：</span>
                    <span>{{ shop_phone }}</span>
                  </div>
                  <div class="phone" style="margin-bottom: 10px">
                    <span>餐厅面积：</span>
                    <span>{{ area }}</span>
                  </div>
                  <div class="phone" style="margin-bottom: 10px">
                    <span>就餐人数：</span>
                    <span>{{ users_amount }}</span>
                  </div>
                  <div
                    class="shop_address"
                    style="line-height: 1.5; width: 240px"
                  >
                    <span>餐厅地址：</span>
                    <span>{{ shop_address }}</span>
                  </div>
                </div>
              </div>
            </ChartBox>
          </div>

          <!-- 投诉意见反馈-->
          <div class="box-left-two">
            <ChartBox title="投诉意见反馈">
              <div
                class="logo"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <img
                  style="
                    border-radius: 10px;
                    margin-top: 5px;
                    width: 330px;
                    height: 220px;
                  "
                  :src="suggestImg"
                  alt=""
                />
              </div>
            </ChartBox>
          </div>

          <!-- 健康证展示 -->
          <div class="box-left-three" @click="gotoHealthyList">
            <ChartBox title="健康证展示" more="更多">
              <el-carousel height="230px" indicator-position="none">
                <el-carousel-item
                  v-for="(item, index) in healthList"
                  :key="index"
                  style="display: flex; justify-content: center"
                >
                  <div>
                    <img
                      :src="
                        item.pic_image +
                        '?x-oss-process=image/resize,w_280,m_lfit'
                      "
                      alt=""
                      style="width: 270px; height: 130px; margin-top: 10px"
                    />
                    <div>
                      <div
                        style="
                          font-size: 12px;
                          border: 1px solid rgb(86, 181, 249);
                          display: inline-block;
                          padding: 5px;
                          border-radius: 5px;
                          color: rgb(86, 181, 249);
                          margin: 5px 20px 10px 0;
                        "
                      >
                        健康证有效
                      </div>
                      <div
                        style="
                          font-size: 12px;
                          border: 1px solid rgb(86, 181, 249);
                          display: inline-block;
                          padding: 5px;
                          border-radius: 5px;
                          color: rgb(86, 181, 249);
                        "
                      >
                        到期日期：{{ item.end_date }}
                      </div>
                    </div>
                    <div style="color: white; font-size: 12px">
                      <div class="heaName">姓名：{{ item.name }}</div>
                      <div class="heaName" style="margin: 5px 0">
                        健康证编号：{{ item.code }}
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </ChartBox>
          </div>
        </aside>
        <section>
          <!-- 证件表格 -->
          <div class="center-buttom">
            <TabPane :tableTeight="tableTeight" :tabList="tabList"></TabPane>
          </div>
          <!-- 信息展示 -->
          <div class="healthCertificate">
            <ChartBox title="信息展示">
              <Swiper :dataList="dataList"></Swiper>
            </ChartBox>
          </div>
        </section>

        <article>
          <!-- 增加一个判断条件 -->
          <!-- <div @click="toRectification">
            <Rectification style="width: 100%; height: 100%"></Rectification>
          </div>
          <div class="box-right-three">
            <ChartBox title="问题整改详情">
              <div v-if="!secondContent.length > 0">
                <img
                  style="height: 232px; width: 100%"
                  src="./../assets/image/NoImg.png"
                  alt=""
                />
              </div>
              <el-carousel
                class="right-ul"
                height="250px"
                indicator-position="none"
                v-else
              >
                <el-carousel-item
                  v-for="(item, index) in secondContentSrc"
                  :key="index"
                >
                  <div class="right-li">
                    <el-image
                      class="img"
                      :src="item.photoUrl"
                      :preview-src-list="[item.photoUrl]"
                    ></el-image>
                    <div
                      style="
                        width: 46%;
                        padding:6px 4px 4px 8px;
                        border-radius: 5px;
                        text-align: left;
                        color: white;
                        font-size: 14px;
                        position: absolute;
                        background-color: rgba(39, 150, 28,.4);
                        bottom: 0;
                        left: 0;
                      "
                    >
                      <div
                        style="
                          display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; word-wrap: break-word; max-height: 3em;
                        "
                      >
                        顾客建议：{{ item.describle }}
                      </div>
                      <div style="margin-top: 5px;">建议日期：2024-07-24</div>
                    </div>
                    <div
                      style="
                        width: 46%;
                        padding:6px 4px 4px 8px;
                        border-radius: 5px;
                        text-align: left;
                        color: white;
                        font-size: 14px;
                        position: absolute;
                        background-color: rgba(39, 150, 28,.4);
                        bottom: 0;
                        right: 0;
                      "
                    >
                      <div
                        style="
                          display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; word-wrap: break-word; max-height: 3em;
                        "
                      >
                        顾客建议：{{ item.describle }}
                      </div>
                      <div style="margin-top: 5px;">建议日期：2024-07-24</div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </ChartBox>
          </div> -->
          <!-- 有店长助手的 -->
          <div class="box-left-one" @click="toAssistantManager">
            <StoreManager style="width: 100%; height: 100%"></StoreManager>
          </div>
          <!-- 每日任务清单详情 -->
          <div class="box-right-three">
            <ChartBox title="每日任务清单详情">
              <div v-if="!secondContent.length > 0">
                <img
                  style="height: 232px; width: 100%"
                  src="./../assets/image/NoImg.png"
                  alt=""
                />
              </div>
              <el-carousel
                class="right-ul"
                height="250px"
                indicator-position="none"
                v-else
              >
                <el-carousel-item
                  v-for="(item, index) in secondContentSrc" :key="index">
                  <div class="right-li">
                    <el-image
                      class="img"
                      :src="item.photoUrl"
                      :preview-src-list="item.photoUrlList"
                    ></el-image>
                    <div
                      style="
                        width: 100%;
                        padding: 8px;
                        border-radius: 5px;
                        text-align: center;
                        color: white;
                        font-size: 14px;
                        position: absolute;
                        background-color: rgb(39, 150, 28);
                        bottom: 0;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                      "
                    >
                      {{ item.describle }}
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </ChartBox>
          </div>
          <!-- 天气预报 -->
          <div class="box-left-whea">
            <ChartBox :title="`${formattedDate}`">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  height: 100%;
                  font-size: 14px;
                  color: white;
                "
              >
                <div
                  class="wheaItem"
                  style="padding: 40px 0 0 70px; width: 150px"
                  v-for="(item, index) in weatherList"
                  :key="index"
                >
                  <div class="wea">
                    <div class="wea-sunny">
                      <img
                        :src="item.weatherImg"
                        style="width: 30px; margin-left: 5px"
                      />
                      <span
                        style="
                          margin-bottom: 15px;
                          display: block;
                          margin-top: 15px;
                        "
                        >{{ !index ? "今日" : "明日" }} {{ item.weather }}</span
                      >
                    </div>
                    <span style="margin-bottom: 15px; display: block">{{
                      item.temperature
                    }}</span>
                  </div>
                  <div class="wea">
                    <div class="wea-sunny">
                      <span style="margin-bottom: 15px; display: block"
                        >{{ item.wind }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </ChartBox>
          </div>
        </article>
      </div>
    </div>
  </v-scale-screen>
</template>
<script>
import ChartBox from "../components/chartBox.vue";
import Chart from "../components/chart.vue";
import AiHeader from "../components/aiHeader.vue";
import TabPane from "../components/tabPane.vue";
import Swiper from "../components/swiper.vue";
import StoreManager from "../components/storeManager.vue";
import Rectification from "../components/rectification.vue";
import { baseImgUrl } from "../utils/request";
import {
  getShopImage,
  getCityWeather,
  getSuggest,
  getshowImgs,
  getHealthTable,
  getBlocks,
  getTaskTemplate,
  getTaskAssess,
  getTaskRecord,
  getTaskRecordByStoreId,
} from "../api/api";
// 引入混入
import { myMixin } from "../common/common.js";

export default {
  data() {
    return {
      AiHeaderQuery: false,
      firstLevelMenu: [],
      getTaskTemplateList: [],
      secondContent: [],
      secondContentSrc: [],
      firstId: "",
      srcList: [],
      srcListIndex: 0,
      weatherList: [],
      isPic: true,
      bigPicIndex: 0,
      dataList: [],
      cleanUpMemory: null,
      healthList: [],
      isCarousel: true,
      suggestImg: "",
      tableTeight: "530",
      tabList: [],
      bigPic: "",
      index: 0,
      currentImg: "",
      currentImgList: [],
      defaultImgB: "",
      defaultImg: "",
      currentVideo: [],
      imageRequestData: {
        page: 1,
      },
      indexs: -1,
      isShowImg: false,
      arr: [],
      datas: [],
      Snap: [],
    };
  },
  mixins: [myMixin],
  methods: {    
    handleChildEvent() {
      this.tabList = []
      this.dataList = []
      this.healthList = []
      this.datas = []
      this.Snap = []
      this.suggestImg =""
      this.getBlocksTable();
      this.getshowImgs();
      this.getHealthTable();
      this.getImage();
      this.getSuggests();
    },
    //获取iframe后给iframe发送消息
    sendIframeMessage(url) {
      var iframe = document.getElementById("iframe");
      setTimeout(() => {
        if (iframe) {
          iframe.contentWindow.postMessage(
            {
              type: "videoUrl",
              videoUrl: url,
            },
            "*"
          );
        }
      }, 500);
      // 确保iframe存在
    },
    // 去温湿度折线图页面
    toTemperHumidity() {
      this.$router.push({ path: "/IoTMonitoring" });
    },
    toMonitor() {
      this.$router.push({ path: "/VideoMonitor" });
    },
    toAssistantManager() {
      this.$router.push({ path: "/StoreManager" });
    },
    toRectification() {
      this.$router.push({ path: "/rectificationRecords" });
    },
    out() {
      this.$refs.mychild.out();
    },
    // 跳转到健康证查询页面
    gotoHealthyList() {
      this.$router.push({ path: "/healthyList" });
    },
    handleImageError() {
      this.defaultImg = "http://aibigscreen.ufoodlife.cn/img/nosignal1.png"; // 图片加载错误时，将src属性绑定到默认的无信号图片路径
    },
    changIndex(e, i) {
      this.indexs = e;
      this.index = e;
    },
    async getTaskTemplateDate() {
      const res = await getTaskTemplate({
        store_id: localStorage.getItem("store_id"),
      });
      // 假设res.data.data是一个包含多个对象的数组
      const extractedData = res.data.data.map((item) => ({
        name: item.name,
        task_template_id: item.task_template_id,
      }));
      this.getTaskTemplateList = extractedData;
    },
    async getTaskRecordByStoreId() {
      const res = await getTaskRecordByStoreId({
        store_id: localStorage.getItem("store_id"),
        search_day: this.searchDay,
      });
      if (res.data.code === 0) {
        this.secondContent = [];
      } else {
        Object.values(res?.data.data)[0].task.forEach((item) => {
          this.secondContent.push(item);
          item.arr_photo_url.forEach((photoUrl) => {
            let obj = {
              photoUrl: photoUrl,
              describle: item.describle,
            };
            this.secondContentSrc.push(obj);
            // 创建一个对象来存储相同describle的photoUrl
            let photoUrlMap = [];

            // 遍历secondContentSrc数组，将相同describle的photoUrl存储到photoUrlMap中
            this.secondContentSrc.forEach((item) => {
                photoUrlMap.push(item.photoUrl);
            });

            // 往secondContentSrc数组中的每个item添加photoUrlList数组
            this.secondContentSrc.forEach((item) => {
              item.photoUrlList = photoUrlMap
            });
          });
        });
      }
    },
    // 查看预警抓拍大图
    viewLargeImage(url, index) {
      this.isPic = true;
      this.bigPicIndex = index;
      this.isShowImg = true;
      this.bigPic = url;
    },
    // 查看大图(视频监控)
    viewLargeVideo(url, index) {
      this.isPic = false;
      this.bigPicIndex = index;
      this.isShowImg = true;
      this.bigPic = url;
    },
    // 上一张
    upImage() {
      let prevIndex = this.bigPicIndex - 1;
      let imgList = this.isPic ? this.Snap : this.currentImgList;
      if (prevIndex < 0) {
        prevIndex = imgList.length - 1;
      }
      this.bigPic = this.isPic ? imgList[prevIndex].img : imgList[prevIndex];
      this.bigPicIndex = prevIndex;
    },
    // 下一张
    nextImage() {
      let nextIndex = this.bigPicIndex + 1;
      let imgList = this.isPic ? this.Snap : this.currentImgList;

      if (nextIndex >= imgList.length) {
        nextIndex = 0;
      }
      this.bigPic = this.isPic ? imgList[nextIndex].img : imgList[nextIndex];
      this.bigPicIndex = nextIndex;
    },
    // 返回原图
    viewBack() {
      this.isShowImg = false;
    },
    // 获取抓拍图片
    async getImage() {
      const res = await getShopImage(this.imageRequestData);
      this.Snap = res.data.data.shopImgs.data.map((item, index) => {
        return {
          title: item.output_class_name,
          time: item.created_at,
          img: !item.alarm_image?.includes("http")
            ? baseImgUrl + item.alarm_image
            : item.alarm_image,
          address: item.channel_address,
          chid: item.chid,
        };
      });
    },
    // 获取天气
    async getCityweather() {
      const res = await getCityWeather({
        city_id: sessionStorage.getItem("city_id"),
      });
      this.weatherList = res.data.data;
    },
    // 获取客诉
    async getSuggests() {
      const res = await getSuggest();
      if (!res.data.data.imgs[0]?.includes("http")) {
        this.suggestImg = baseImgUrl + res.data.data.imgs[0];
      } else {
        this.suggestImg = res.data.data.imgs[0];
      }
    },
    // 获取信息展示
    async getshowImgs() {
      const res = await getshowImgs({ from: "home" });
      this.dataList = res.data.data.imgs.map((item) => {
        if (!item.includes("http")) {
          item = baseImgUrl + item;
        }
        return item;
      });
    },
    // 健康证信息展示
    async getHealthTable() {
      const res = await getHealthTable({
        order: "desc",
        orderby: "end_date",
      });
      this.healthList = res.data.data[0].data.map((item) => {
        // 判断对象中是否有 pic_image 属性
        if (!item.pic_image?.includes("http")) {
          item.pic_image = baseImgUrl + item.pic_image;
        }
        return item;
      });
    },
    // 获取大屏展示内容
    async getBlocksTable() {
      const res = await getBlocks({ from: "home" });
      this.tabList = res.data.data.map((str) => {
        return { label: str };
      });
    },
  },
  // watch: {
  //   getTaskTemplateList: {
  //     handler: function (newVal, oldVal) {
  //       if (newVal && newVal.length > 0) {
  //         this.getTask(0);
  //       }
  //     },
  //     immediate: true,
  //   },
  //   firstId: {
  //     handler: function (newVal, oldVal) {
  //       if (newVal) {
  //         this.getTaskRecordData(this.firstId);
  //       }
  //     },
  //     immediate: true,
  //   },
  //   srcList: {
  //     handler: function (newVal, oldVal) {
  //       if (this.srcListIndex < 3) {
  //         this.getTask(this.srcListIndex);
  //       }
  //     },
  //     immediate: true,
  //   },
  // },
  components: {
    ChartBox,
    Chart,
    AiHeader,
    TabPane,
    Swiper,
    StoreManager,
    Rectification,
  },
  created() {
    this.getBlocksTable();
    this.getshowImgs();
    this.getHealthTable();
    this.getImage();
    this.cleanUpMemory = setInterval(() => {
      this.getImage();
    }, 15 * 60 * 1000);
    this.getCityweather();
    this.getSuggests();
    this.getTaskTemplateDate();
    this.getTaskRecordByStoreId();
    // this.getTaskRecordData()
  },
  mounted() {
    document.title = "优食安数字食堂云平台";
    if (this.$route.query.from === "aiHeader") {
      this.AiHeaderQuery = true;
    }
  },
  computed: {
    searchDay() {
      const dateTimeString = new Date();
      const date = new Date(dateTimeString);
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // 月份是从0开始计数的，需要加1
      const day = date.getDate();
      const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${
        day < 10 ? "0" + day : day
      }`;
      return formattedDate;
    },
  },
  beforeDestroy() {
    clearInterval(this.cleanUpMemory);
    this.index = 0;
  },
};
</script>
<style lang="scss" scoped>
.carouselBox {
  margin: 0 auto;
  width: 100%;
  height: 230px;
  // background-color: #2276f5;
  .carousel {
    width: 100%;
    height: 420px;
    .el-car-item {
      width: 100%;
      height: 420px;
      display: flex;
      .divSrc {
        width: 323px;
        height: 230px;
        background: #fff;
        margin-right: 46px;

        .img {
          width: 323px;
          height: 230px;
        }
        .title {
          width: 90%;
          height: 80px;
          line-height: 80px;
          margin: 0 auto;
          text-align: center;
          font-size: 20px;
          font-weight: bold;
          color: #222222;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    ::v-deep .el-carousel__arrow {
      background: #99a9bf !important;
      display: block !important;
      margin-top: 65px;
    }
  }
  .el-car-item {
    width: 100%;
    display: flex;

    .img {
      width: 284px;
      height: 184px;
      margin-right: 20px;
      cursor: pointer;
    }
  }
}
// 查看大图样式
.imgwrap {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 999;

  .next {
    position: absolute;
    width: 50px;
    height: 50px;
    right: 30px;
    top: 50%;
  }
  .up {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 30px;
    top: 50%;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.container // 滚动条样式
  
      ::-webkit-scrollbar-track-piece {
  background: rgb(1, 22, 55);
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  /* 横向滚动条的大小 */
}

::-webkit-scrollbar-thumb {
  background: #99a9bf;
  border-radius: 20px;
}

::v-deep .el-dropdown .el-button-group {
  display: flex;
}
::v-deep .el-button-group > .el-button:not(:last-child) {
  width: 100px;
}
::v-deep .el-button {
  text-overflow: ellipsis;
  overflow: hidden;
}
// 表格单元格
::v-deep .box-container .el-table .el-table__cell {
  // padding: 20px 7px;
  color: white !important;
  background-color: rgb(1, 20, 49) !important;
}

.container {
  height: 100%;
  width: 100%;
  background: url("../assets/image/bg.577ccc4c.jpg");

  .box-left-one {
    .logo {
      img {
        width: 180px;
        height: 180px;
      }

      .head span,
      .phone span {
        font-size: 15px;
      }
    }
  }

  .box-left-whea {
    grid-area: 1/5/2/6;

    .wheaItem {
      width: 49%;
      height: calc(100% - 45px);

      .wea {
        .wea-sunny {
          i {
            color: rgb(86, 181, 249);
            font-size: 28px;
            margin-right: 5px;
          }
        }

        span {
          color: white;
          font-size: 17px;
        }
      }
    }
  }
}

/* 中间 */
.box-container {
  min-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 20px;
  height: calc(100% - 130px);
  > section,
  > aside,
  > article {
    flex: 0 0 20%;
    width: 0;
    > * {
      height: 300px;
      & + * {
        margin-top: 20px;
      }
    }

    .center-buttom {
      box-sizing: border-box;
      height: 620px;
    }
  }
  > section {
    flex: 1;
    margin: 0 20px;
  }
}

.box-right-one {
  // 树状图
  .filter-tree {
    color: #fff;
    font-size: 12px;
    width: calc(100% - 90px);
    margin: 0 auto;
    padding: 10px;
    max-height: 170px;
    overflow-y: scroll;
    background-color: transparent;

    ::v-deep .el-tree-node__expand-icon {
      font-size: 18px;
    }
  }
}

.box-left-two {
  grid-area: 2/1/3/2;

  .temperContainer {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    color: white;

    .tempItem {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-grow: 1;

      .location {
        // width: 100%;
        height: 50px;
        line-height: 50px;
      }

      .temper {
        display: flex;
        align-items: center;
        height: 80px;

        img {
          margin-right: 4px;
        }
      }

      .water {
        display: flex;
        align-items: center;
        height: 80px;

        // margin-left: 8px;
        img {
          margin-right: 3px;
        }
      }
    }
  }
}

.box-left-three {
  grid-area: 3/1/4/2;
  overflow: hidden;
}

.center-top {
  .one {
    box-sizing: border-box;
    width: 100%;

    margin: 0 auto;
    height: 100%;
    color: white;
    // background-color: black;
    // border: 2px solid rgb(42, 48, 70);

    img {
      height: 260px;
      width: 350px;
    }
  }
}

.center-buttom {
  display: flex;
  justify-content: space-between;
  color: white;
  border: 2px solid rgb(34, 77, 128);
  border-radius: 10px;
  padding: 0 10px;
}

.box-right-three {
  height: 100%;

  .right-ul {
    display: flex;
    flex-direction: column;
    max-height: 100%;

    .right-li {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 100%;
      height: 100%;
      //   background-image: url("../assets/image/img-backgroud.png");
      background-color: rgb(1, 20, 49);
      .img {
        width: 100%;
        border-radius: 5px;
      }
    }
  }
}

.currentBg {
  background: rgb(0, 228, 255) !important;
}

.noCurrentBg {
  color: white !important;
}
</style>
<style lang="scss" scoped>
iframe {
  width: 100%;
  height: 100%;
  border: none;
}
// 表单格式
::v-deep .el-table {
  .el-table__body {
    height: 100%;
  }
}

// :not(.el-table thead.is-group th.el-table__cell)
::v-deep .box-container .el-table .el-table__cell {
  // border-right: 1px solid white;
  padding: 20px 7px;
  color: white !important;
}
::v-deep .el-table th.el-table__cell.is-leaf {
  padding: 20px 0 !important;
  font-size: 16px;
  border: none !important;
}
::v-deep .box-container .el-tabs .el-tabs__item:not(:first-child) {
  color: #a27b67;
}

::v-deep .box-container .el-tabs .el-tabs__item:hover {
  color: #a27b67 !important;
}

.el-tabs__active-bar {
  background-color: #a27b67 !important;
}

.box-container .el-tabs {
  width: 100%;
  color: #fffff0;
}

.center-buttom .el-tab-pane .el-image {
  width: 40px;
  height: 40px;
}

// Card样式
.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 50%;
  // display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.center-buttom .el-button {
  padding: 3px 4px;
}
</style>
