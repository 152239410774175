<template>
  <div class="swiperWrap mySwiper">
    <!-- 查看大图容器 -->
    <div class="imgwrap" v-if="isShowImg">
      <img
        style="
          position: absolute;
          width: 50px;
          height: 50px;
          right: 30px;
          top: 50%;
        "
        class="nextStyle"
        src="../assets/image/next.png"
        @click="nextSwiperImage()"
      />
      <img
        style="
          position: absolute;
          width: 50px;
          height: 50px;
          left: 30px;
          top: 50%;
        "
        class="upStyle"
        src="../assets/image/up.png"
        @click="upSwiperImage()"
      />
      <img
        style="width: 100%; height: 100%"
        class="swiperItemImg"
        :src="getSrc"
        alt="点击关闭"
        @click="viewBack"
      />
    </div>

    <div class="swiper-wrapper" style="display: flex">
      <div v-if="dataList.length == 0">
        <img
          style="height: 220px; width: 100%"
          src="./../assets/image/NoImg.png"
          alt=""
        />
      </div>
      <template v-else>
        <div
          class="swiper-slide"
          v-for="(item, index) in dataList"
          :key="index"
        >
        <!-- <el-image
              style="width: 320px; height: 210px; margin-top: 10px"
              :key="index"
              :src="item"
              :preview-src-list="dataList"
            ></el-image>

          <img
           v-if="dataList.length<3"
            style="height: 220px; width: 100%"
            src="./../assets/image/NoImg.png"
            alt=""
          /> -->
          <img
            :src="item + '?x-oss-process=image/resize,w_330,m_lfit'"
            :data-url="item"
            :data-id="index"
            :data-isOpen="item"
          />
        </div>
      </template>
    </div>
    <div class="swiper-pagination"></div>
  </div>
</template>
<script>
import Swiper from "swiper";
import "../assets/css/swiper-bundle.min.css";
export default {
  data() {
    return {
      isShowImg: false,
      imgIndex: undefined,
    };
  },

  props: ["dataList"],
  computed: {
    getSrc() {
      if (this.dataList) {
        return this.dataList[this.imgIndex];
      }
    },
  },
  methods: {
    // 初始化轮播图
    initSwiper() {
      var self = this;
      var swiper = new Swiper(".mySwiper", {
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        loop: true,
        slidesPerView: 3,
        spaceBetween: 30,
        on: {
          click: function (e) {
            swiper.allowTouchMove = false; // 禁用Swiper的滑动操作
            let data = e.target.dataset;
            self.viewinfoLargeImage(data);
            swiper.allowTouchMove = true; // 恢复Swiper的滑动操作
          },
        },
      });
    },
    // 信息展示查看大图
    viewinfoLargeImage(data) {
      if (data && data.id) {
        this.imgIndex = JSON.parse(JSON.stringify(data.id));
        setTimeout(() => {
          this.isShowImg = true;
        }, 20);
      } else {
        console.error("data.id is undefined or null");
      }
    },
    // 下一张
    nextSwiperImage() {
      if (this.imgIndex >= this.dataList.length - 1) {
        this.imgIndex = 0;
      } else {
        this.imgIndex++;
      }
    },
    // 上一张
    upSwiperImage() {
      if (this.imgIndex <= 0) {
        this.imgIndex = this.dataList.length - 1;
      } else {
        this.imgIndex--;
      }
    },
    // 返回原图
    viewBack() {
      this.isShowImg = false;
    },
  },
  mounted() {
    this.initSwiper();
  },
  watch: {
    dataList: {
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.initSwiper();
          });
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.swiperWrap {
  width: 100%;
  height: 100%;
  margin-top: 8px;
  overflow: hidden;
}

.swiper-slide {
  width: 330px;
  height: 216px;
  margin-right: 30px;
}

.swiper-slide img {
  display: block;
  width: 330px;
  /* height: 100%; */
  /* height: 216px; */
  border-radius: 5px;
}

.imgwrap {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 9999999;
}
</style>
